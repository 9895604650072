import { Component } from '@angular/core';

@Component({
  selector: 'vp-board',
  templateUrl: './profiles-board.component.html'
})

export class ProfilesBoardComponent {
  scrollDistance = 1;
  scrollUpDistance = 1;
  throttle = 300;
}
