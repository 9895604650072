
	<div id="legend" class="legend open">
		<button  onclick="toggleLegend()">
			<span></span>
			<span></span>
			<span></span>
		</button>
		<div class="legend-info">
			<div>
				<h5>Total</h5>
				<div class="gradient"></div>
				<div class="labels">
					<span>Low</span>
					<span>High</span>
				</div>
			</div>
			<div>
				<div class="circles">
					<span></span>
					<span></span>
					<span></span>
				</div>
				<div class="labels">
					<span>Low</span>
					<span>High</span>
				</div>
			</div>
		</div>
	</div>