import { LocationIndicatorModel } from '../../models/location-indicator.model';
import { ICovidIndicatorData } from '@vantage-platform/store/lib/models/i-covid-indicator-data';
import { IsPercentage } from '../functions/IsPercentageValue';

const decimalPlaces = 2;

export const mapIndicatorToLocationIndicatorModel = (
  indicatorValue: ICovidIndicatorData,
  unitOfMeasure: string
): LocationIndicatorModel => {
  const locationIndicatorModel = new LocationIndicatorModel();
  let value: string = `${indicatorValue.value}`;

  if (IsPercentage(unitOfMeasure)) {
    value = `${indicatorValue.value.toFixed(decimalPlaces)} %`;
  }

  locationIndicatorModel.id = indicatorValue.indicatorCode;
  locationIndicatorModel.isSelected = true;
  locationIndicatorModel.val = value;
  locationIndicatorModel.display = indicatorValue.indicatorName;
  return locationIndicatorModel;
};
