import { IndicatorType } from '../enums/indicatorType.enum';

export class IndicatorModel{
    id:string; 
    display:string; 
    description: string;
    color: string; 
    type: IndicatorType;
    order: number; 
    isSelected: boolean;

    constructor(){
        this.isSelected = false;
    }
}