<!-- <ng-container *ngIf="canDisplaySummary()">
    <div id="map-indicator-summary">
      <div>
      <h4 id="country-name">{{selectedLocation.name}}</h4>
        <ul *ngFor="let indicator of getSelectedIndicators()">
        <li id="{{indicator.id}}" [style.color]="indicator.color"> {{indicator.display}}
        <span id="{{indicator.id}}_detail">{{indicator.description}} {{indicator.val}}</span></li>
        </ul>
        </div>
  </div>
  </ng-container> -->
  

  <ng-container *ngIf="canDisplaySummary()">
    <!-- <button class="closebtn" onclick="closeInfo()"></button> -->
    <h1>{{selectedLocation.name}}</h1>
    <div class="stats">
      <ng-container *ngFor="let indicator of getSelectedIndicators()">
        <section [class]="getIndicatorValueClass(indicator.id)">
          <div>
          <h4>{{indicator.display}}</h4>
        </div>
          <h5>{{getIndicatorValue(indicator.val)}}</h5>
        </section>
        </ng-container>
    </div>
  </ng-container>