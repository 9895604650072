<div class="lightbox-container" id="modal" style="display:block;">
	<div class="lightbox small">
		<div class="card-section card-header">No ward data loaded:</div>
		
			<div class="card-section card-header">				
				<label>Please first select a province to view ward data.</label>
			</div>

		<div class="button-container-right">
			<button (click)="gobackToSouthAfrica()">Ok</button>
		</div>
	</div>
</div>