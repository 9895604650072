<!--  DESKTOP ONLY -->
<div class="category-container" *ngIf="showDesktopVersion()">
  
  <!-- Questions -->
  <div class="category">
    <h3 class="category-header">Questions</h3>
    <ul>
      <li
        *ngFor="let question of covidQuestions"
        [class]="question.isSelected
            ? 'unavailable tool-tip-canary'
            : 'tool-tip-canary'"
      (click)= "selectQuestion(question)"      
      >
        {{ question.question }}
      </li>
    </ul>
  </div>

  <ng-container *ngFor="let domain of informationDomains">
    <div class="category">
      <h3 class="category-header">{{ domain.informationDomainName }}</h3>
      <ul>
        <li
          *ngFor="
            let indicator of domain.indicators
              | filterdata: searchTerm:'indicatorName'
          "
          [attr.data-tooltip]="indicator.indicatorName"
          [class]="
            indicator.isSelected
              ? 'unavailable tool-tip-canary'
              : 'tool-tip-canary'
          "
          [innerHTML]="indicator.indicatorName | highlight: searchTerm"
          (click)="selectIndicator(indicator)"
        ></li>
      </ul>
    </div>
  </ng-container>
</div>

<!--  MOBILE ONLY -->
<div class="column" *ngIf="!showDesktopVersion()">

   <!-- Questions -->
  <div class="classification">
    <h3 class="class-header">Questions</h3>
    <ul>
      <li
        *ngFor="let question of covidQuestions"
        [class]="question.isSelected ? 'unavailable' : ''"
        (click)= "selectQuestion(question)"      
      >
        {{ question.question }}
      </li>
    </ul>
  </div>


  <ng-container *ngFor="let domain of informationDomains">
    <div class="classification">
      <h3 class="class-header">{{ domain.informationDomainName }}</h3>
      <ul>
        <li
          *ngFor="
            let indicator of domain.indicators
              | filterdata: searchTerm:'indicatorName'
          "
          [class]="indicator.isSelected ? 'unavailable' : ''"
          [innerHTML]="indicator.indicatorName | highlight: searchTerm"
          (click)="selectIndicator(indicator)"
        ></li>
      </ul>
    </div>
  </ng-container>
</div>
