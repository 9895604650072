import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList
} from '@angular/core';
import { SortableComponent } from 'ngx-bootstrap/sortable';
import { InformationDomainModel } from '../../models/information-domain.model';
import { IIndicator } from '@vantage-platform/store/lib/models/i-indicator';
import { ICovidQuestion } from '@vantage-platform/store/lib/models/i-covid-question';

@Component({
  selector: 'vp-indicator-dragdrop',
  templateUrl: './indicator-dragdrop.component.html',
  styleUrls: ['./indicator-dragdrop.component.scss']
})
export class IndicatorDragDropComponent {
  @Input() indicators: IIndicator[];
  @Input() informationDomains: InformationDomainModel[];
  @Input() covidQuestions: ICovidQuestion[];

  @Output() changePrimaryIndicatorEventEmitter: EventEmitter<IIndicator> = new EventEmitter<IIndicator>();
  @Output() changeSecondaryIndicatorEventEmitter: EventEmitter<IIndicator> = new EventEmitter<IIndicator>();
  @Output() selectIndicatorEventEmitter: EventEmitter<IIndicator> = new EventEmitter<IIndicator>();
  @Output() removeIndicatorEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeSecondIndicatorEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  @ViewChildren(SortableComponent)
  sortableComponents: QueryList<SortableComponent>;

  selectedIndicators: IIndicator[] = new Array<IIndicator>();
  selectedIndicatorsMobile: IIndicator[] = new Array<IIndicator>();
  selectedIndicatorsMobileMegaMenu: IIndicator[] = new Array<IIndicator>();
  primaryIndicatorName: string = '';
  secondaryIndicatorName: string = '';
  showDesktopVersionInd: boolean = true;
  searchTerm: string = '';

  addItemToSortableList(): void {
    //TODO: When fix is available follow -> https://github.com/valor-software/ngx-bootstrap/issues/1716
    if (this.selectedIndicators.length > 0) {
      this.indicators.forEach(indicator => {
        if (!this.selectedIndicators.includes(indicator)) {
          this.selectedIndicators.push(indicator);
          this.selectedIndicatorsMobile = this.selectedIndicators;
          this.selectedIndicatorsMobileMegaMenu = this.selectedIndicators;
        }
      });
    } else {
      this.selectedIndicators = this.indicators;
      this.selectedIndicatorsMobile = this.selectedIndicators;
      this.selectedIndicatorsMobileMegaMenu = this.selectedIndicators;
    }

    this.updateSortableList();
    this.getPrimaryAndSecondaryIndicatorNames();
  }

  getSearchTerm(): string {
    return this.searchTerm;
  }

  private getPrimaryAndSecondaryIndicatorNames(): void {
    this.getPrimaryIndicatorName();
    this.getSecondaryIndicatorName();
  }

  private getPrimaryIndicatorName(): void {
    if (this.selectedIndicators.length === 0) return;

    this.primaryIndicatorName = this.selectedIndicators[0].indicatorName;
  }

  private getSecondaryIndicatorName(): void {
    if (this.selectedIndicators.length <= 1) return;

    this.secondaryIndicatorName = this.selectedIndicators[1].indicatorName;
  }

  changeIndicatorOrder(): void {
    if (
      typeof this.selectedIndicators === 'undefined' ||
      this.selectedIndicators.length === 0
    )
      return;

    if (
      this.primaryIndicatorName !== this.selectedIndicators[0].indicatorName
    ) {
      this.getPrimaryIndicatorName();
      this.changePrimaryIndicatorEventEmitter.emit(this.selectedIndicators[0]);
    }

    if (this.selectedIndicators.length <= 1) return;

    if (
      this.secondaryIndicatorName !== this.selectedIndicators[1].indicatorName
    ) {
      this.getSecondaryIndicatorName();
      this.changeSecondaryIndicatorEventEmitter.emit(
        this.selectedIndicators[1]
      );
    }
  }

  removeIndicator(indicatorDisplayName: string): void {
    const indicator = this.selectedIndicators.find(
      a => a.indicatorName === indicatorDisplayName
    );
    const index = this.selectedIndicators.findIndex(
      a => a.indicatorName === indicatorDisplayName
    );

    this.removeIndicatorEventEmitter.emit({
      indicator: indicator,
      position: index + 1
    });
    this.removeFromIndicatorList(indicator);
    this.updateSortableList();
    this.deselectQuestion(indicator.indicatorCode);
  }

  private deselectQuestion(indicatorCode: string): void {
    this.covidQuestions.forEach(a => {
      if (a.isSelected) {
        if (a.indicatorCodes.includes(indicatorCode)) a.isSelected = false;
      }
    });
  }

  private updateIndicators(): void {
    this.updatePrimaryIndicators();
    this.updateSecondaryIndicator();
  }

  private updatePrimaryIndicators(): void {
    if (this.selectedIndicators.length > 0)
      this.changePrimaryIndicatorEventEmitter.emit(this.selectedIndicators[0]);
  }

  private updateSecondaryIndicator(): void {
    if (this.selectedIndicators.length > 1 && this.secondaryIndicatorName !== this.selectedIndicators[1].indicatorName)
      this.changeSecondaryIndicatorEventEmitter.emit(
        this.selectedIndicators[1]
      );
    else {
      this.secondaryIndicatorName = '';
      this.removeSecondIndicatorEventEmitter.emit();
    }
  }

  private removeFromIndicatorList(indicator: IIndicator): void {
    const index = this.selectedIndicators.indexOf(indicator, 0);
    if (index === -1) return;

    this.selectedIndicators.splice(index, 1);
  }

  private updateSortableList(): void {
    this.sortableComponents.forEach(sortableComponent =>
      sortableComponent.writeValue(this.selectedIndicators)
    );
  }

  showRemoveIndicatorButton = (): boolean => this.selectedIndicators.length > 1;

  selectIndicator(indicator: IIndicator): void {  
    this.selectIndicatorEventEmitter.emit(indicator);
    this.searchTerm = '';
    this.updateIndicators();
  }

  removeAllSelectedIndicators(): void {
    this.selectedIndicators.forEach(a => this.removeIndicator(a.indicatorName));
  }
}
