import { MapGeoLevel } from '../../enums/enums';

export const GetApiGeoLevel = (mapGeoLevel:MapGeoLevel): string => {
    switch (mapGeoLevel) {
        case MapGeoLevel.SouthAfrica:
          return 'Province';
        case MapGeoLevel.District:
          return 'District';
        case MapGeoLevel.Local:
          return 'Local Municipality';
        case MapGeoLevel.Ward:
          return 'Ward';
        default:
          console.error("No match found for API geo level");
      }
}