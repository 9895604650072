import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'vp-map-legend',
  templateUrl: './map-legend.component.html',
  styleUrls: ['./map-legend.component.scss']
})
export class MapLegendComponent {

}
