import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { SortableModule, CollapseModule } from 'ngx-bootstrap';


import { MainMapComponent } from './main-map/main-map.component';
import { ProfilesBoardComponent } from './profiles-board/profiles-board.component';
import { MapIndicatorSummaryComponent } from './map-indicator-summary/map-indicator-summary.component';
import { IndicatorDragDropComponent } from './indicator-dragdrop/indicator-dragdrop.component';
import { IndicatorSearchComponent } from './indicator-search/indicator-search.component';
import { TimePeriodComponent } from './time-period/time-period.component';

import { FilterDataPipe } from '../data-utilities/pipes/filter-data.pipe';
import { HighlightSearchPipe } from '../data-utilities/pipes/highlight-search.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MapLegendComponent } from './map-legend/map-legend.component';
import { NoWardLevelOverlayComponent } from './no-ward-level-data-overlay/no-ward-level-overlay-component';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';

const routes: Routes = [];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SortableModule.forRoot(),
    CollapseModule.forRoot(),
    NgxSpinnerModule,
    RouterModule.forRoot(routes)
  ],
  declarations: [
    MainMapComponent,
    ProfilesBoardComponent,
    MapIndicatorSummaryComponent,
    IndicatorDragDropComponent,
    IndicatorSearchComponent,
    MapLegendComponent,
    NoWardLevelOverlayComponent,
    FilterDataPipe,
    HighlightSearchPipe,
    LoadingScreenComponent,
    TimePeriodComponent
  ],
  exports: [ProfilesBoardComponent]
})

export class ProfilesScreenModule {}
