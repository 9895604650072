<div class="map-placeholder" id="mapPlaceholder"></div>
<div class="map-container">
  <!-- <div class="map-searchblock">
		<input type="text" placeholder="Search Location"/>
	</div> 
	<button class="multiselect" id="multiselectbtn" onclick="multiselectFn()"><span class="icon-layers mobile-only"><span></span></span><span class="desktop-only">select multiple</span></button> -->

  <div class="breadcrumbs-container">
    <!-- <button class="back"><span class="desktop-only">BACK</span></button> -->
    <button class="ghost togglebtn right" id="toggleMap" (click)="toggleMap()">
      <span class="icon-globe-blue"></span>&nbsp;<span class="toggleShow"
        >Satellite </span
      ><span class="toggleHide">Heat </span>&nbsp;Map
    </button>

    <div class="breadcrumbs">
      <ng-container *ngFor="let geoLevelBreadcrumb of geoLevelBreadcrumbs; let i = index">
        <a href="javascript:void(0)" (click)="goBackToGeoLevel(geoLevelBreadcrumb)">{{ geoLevelBreadcrumb.geoLocationName }}</a>
        <span *ngIf="i != geoLevelBreadcrumbs.length -1" class="connector"></span>
      </ng-container>
     <ng-container *ngIf="displayClearButton()">
      <a href="javascript:void(0)" (click)="clearLastBreadCrumbButStayOnSelection()">Clear</a>
     </ng-container>
    </div>
  </div>

  <div id="map"></div>
  <vp-map-legend></vp-map-legend>
</div>

<div *ngIf="showPopup" class="info" id="infoBox">
  <vp-map-indicator-summary
    [selectedLocation]="selectedLocation"
    [indicatorCodeOrder]="getIndicatorCode()"
  ></vp-map-indicator-summary>
</div>

<vp-indicator-dragdrop
  [(indicators)]="selectedIndicators"
  [(informationDomains)]="informationDomains"
  [(covidQuestions)]= "covidQuestions"
  (changePrimaryIndicatorEventEmitter)="setSelectedPrimaryIndicator($event)"
  (changeSecondaryIndicatorEventEmitter)="setSelectedSecondaryIndicator($event)"
  (selectIndicatorEventEmitter)="selectIndicator($event)"
  (removeIndicatorEventEmitter)="removeIndicator($event)"
  (removeSecondIndicatorEventEmitter)="removeSecondIndicator()"
></vp-indicator-dragdrop>

<vp-time-period
*ngIf="timePeriods"
[(timePeriods)]="timePeriods"
(selectTimePeriodEventEmitter)="selectTimePeriod($event)"
></vp-time-period>


<!-- <button class="switch-view mobile-only" id="switchView" onclick="switchview()">
  <span></span><span></span>
</button> -->

<no-ward-level 
*ngIf="showNoWardDataOverlay"
(goToSouthAfricaEventEmitter)="goToSouthAfrica($event)">
</no-ward-level>



<ng-container *ngIf="displayLoadingScreen || !completedFetchingData()">
  <vp-loading-screen
  (cancelEventEmmitter)="cancelLoadingScreenEvent()"
  >
  </vp-loading-screen>
  </ng-container>
