export class LocationIndicatorModel{
    id:string;
    display: string;
    isSelected: boolean;
    indicatorName: string;
    geoLocation: string;
    parentGeoLocationID: string;
    parentGeoLocationName: string;
    year: string;
    informationDomain: string;
    latitude: number;
    longitude: number;
    val:string;
}
