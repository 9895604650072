import { IGeoLocationData } from '@vantage-platform/store/lib/models/i-geo-location-data';
import { IGeoLocationMunicipalityData } from '@vantage-platform/store/lib/models/i-geo-location-municipality-data';

export const MapMunicipalityToGeoLocationData = (
  municipalities: IGeoLocationMunicipalityData[],
  provinceLocationId: number
): IGeoLocationData[] => {
  let geoLocationDataList: IGeoLocationData[] = [];

  municipalities.forEach(a => {
    const geoLocationData: IGeoLocationData = {
      levelCode: a.wardLevelCode,
      parentLevelCode: a.localMunicipalityLevelCode,
      parentLocationID: a.localMunicipalityLocationID,
      parentLocationCode: a.localMunicipalityLocationCode,
      parentLocationName: a.localMunicipalityLocationName,
      levelName: a.wardLevelName,
      levelDescription: `${a.wardDistrictLocationId}`,
      locationID: a.wardLocationID,
      locationCode: a.wardLocationCode,
      locationName: a.wardLocationName,
      latitude: a.wardLatitude,
      longitude: a.wardLongitude,
      provinceLocationId: provinceLocationId
    };

    geoLocationDataList = [...geoLocationDataList, geoLocationData];
  });

  return geoLocationDataList;
};
