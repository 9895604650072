import { MapGeoLevel } from '../../enums/enums';

export const FormatGeoLocationName = (value: string, mapGeoLevel:MapGeoLevel): string => {
    switch (mapGeoLevel) {
      case MapGeoLevel.Ward:
        return GetWardText(value);

      default:
        return value;
    }
  }

  const GetWardText = (value: string): string => {
    const regExp = /\(.*?\)/;
    const valueToReplace = regExp.exec(value);

    if (!valueToReplace) return value;

    return value.replace(valueToReplace[0], '');
  }