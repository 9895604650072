import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IInformationDomain } from '@vantage-platform/store/lib/models/i-information-domain';
import { IIndicator } from '@vantage-platform/store/lib/models/i-indicator';
import { ICovidQuestion } from '@vantage-platform/store/lib/models/i-covid-question';

@Component({
  selector: 'vp-indicator-search',
  templateUrl: './indicator-search.component.html',
  styleUrls: ['./indicator-search.component.scss']
})
export class IndicatorSearchComponent {
  @Input() informationDomains: IInformationDomain[];
  @Input() searchTerm: string;
  @Input() showDesktopVersionInd: boolean;
  @Input() covidQuestions: ICovidQuestion[];
  @Output() selectIndicatorEventEmitter: EventEmitter<IIndicator> = new EventEmitter<IIndicator>();
  @Output() removeAllSelectedIndicatorsEventEmmitter: EventEmitter<void> = new EventEmitter<void>();
  isCollapsed = true;

  selectIndicator($event: IIndicator): void {
    this.selectIndicatorEventEmitter.emit($event);
    this.toggleIndicatorList();
    this.clearSearch();
  }

  toggleIndicatorList(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  selectQuestion(question: ICovidQuestion) {  
    this.removeAllCurrentIndicators();
    this.covidQuestions.find(a => {
      if (a.question === question.question) {
        a.isSelected = true;
      } else {
        a.isSelected = false;
      }
    });

    this.selectMultipleIndicators(question);
  }

  private selectMultipleIndicators(question:ICovidQuestion): void{
    const indicators = this.getIndicators();
    question.indicatorCodes.forEach(code => {
      let indicator = indicators.find(a => a.indicatorCode === code);
      this.selectIndicator(indicator);
    });
  }

  private getIndicators(): IIndicator[] {
    let indicators = [];
    this.informationDomains.forEach(
      a => (indicators = [...indicators, ...a.indicators])
    );
    return indicators;
  }

  private clearSearch(): void {
    this.searchTerm = '';
  }

  showDesktopVersion(): boolean {
    return this.showDesktopVersionInd;
  }

  removeAllCurrentIndicators(): void {
    this.removeAllSelectedIndicatorsEventEmmitter.emit();
  }
}
