import { IndicatorModel } from '../../models/indicator.model';
import { IIndicator } from '@vantage-platform/store/lib/models/i-indicator';

export const extractSelectedIndicators = (
  selectedIndicators: IIndicator[],
  indicator: IIndicator
) => {
  return indicator.isSelected && !selectedIndicators.includes(indicator)
    ? [...selectedIndicators, indicator]
    : selectedIndicators;
};
