import { Component, ViewChild, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';

import {
  primaryIndicatorDefinition,
  secondaryIndicatorDefinition,
  defaultSelectedIndicators,
  textLayerDefinition,
  indicatorsThatShouldNotDisplayNoData
} from './indicatorDefinitions';
import {
  MapLayers,
  MapGeoLevel,
  ShapeFileMinZoomLevel
} from '../../enums/enums';
import { LocationModel } from '../../models/location.model';
import { IndicatorModel } from '../../models/indicator.model';
import { MapIndicatorSummaryComponent } from '../map-indicator-summary/map-indicator-summary.component';
import { NoWardLevelOverlayComponent } from '../no-ward-level-data-overlay/no-ward-level-overlay-component';
import { IndicatorDragDropComponent } from '../indicator-dragdrop/indicator-dragdrop.component';
import { extractIndicatorsFromDomains } from '../../data-utilities/reducers/extractIndicatorsFromDomains.reducer';
import { mapIndicatorToLocationIndicatorModel } from '../../data-utilities/mappers/IndicatorToLocationIndicatorModel.map';
import { extractSelectedIndicators } from '../../data-utilities/reducers/extractSelectedIndicators.reducer';
import { TimePeriodComponent } from '../time-period/time-period.component';

import { CovidDataService } from '@vantage-platform/store/lib/services/covid-data.service';
import { MapBoxService } from '@vantage-platform/store/lib/services/mapbox.service';
import { IInformationDomain } from '@vantage-platform/store/lib/models/i-information-domain';
import { IGeoLevelBreadCrumb } from '@vantage-platform/store/lib/models/i-geo-level-breadcrumb';
import { IIndicator } from '@vantage-platform/store/lib/models/i-indicator';
import { LocationIndicatorModel } from '../../models/location-indicator.model';
import { ActivatedRoute } from '@angular/router';
import { CovidIndicatorToInformationDomainMap } from '../../data-utilities/mappers/CovidIndicatorToInformationDomain.map';
import { ICovidIndicator } from '@vantage-platform/store/lib/models/i-covid-indicator';
import { ICovidIndicatorData } from '@vantage-platform/store/lib/models/i-covid-indicator-data';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, Observable, fromEvent } from 'rxjs';
import { IMapBoxProperties } from '@vantage-platform/store/lib/models/i-mapbox-properties';
import { ICovidQuestion } from '@vantage-platform/store/lib/models/i-covid-question';
import { IsPercentage } from '../../data-utilities/functions/IsPercentageValue';
import { ToPercentage } from '../../data-utilities/functions/NumberCalculations';
import { IParentGeoLocation } from '@vantage-platform/store/lib/i-parent-geo-location';
import { FormatGeoLocationName } from '../../data-utilities/functions/FormatGeoLocationName';
import { MapLegendComponent } from '../map-legend/map-legend.component';
import { GetApiGeoLevel } from '../../data-utilities/functions/ApiDataMappers';
import { IGeoLocationData } from '@vantage-platform/store/lib/models/i-geo-location-data';
import { IGeoLocationMunicipalityData } from '@vantage-platform/store/lib/models/i-geo-location-municipality-data';
import { MapMunicipalityToGeoLocationData } from '../../data-utilities/mappers/MunicipailityToGeoLocationData.map';
import { getDateDifferenceMs } from '../../data-utilities/functions/DateCalculations';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { ICovidTimePeriod } from '@vantage-platform/store/lib/models/i-covid-time-period';

@Component({
  selector: 'vp-main-map',
  templateUrl: './main-map.component.html',
  styleUrls: ['main-map.component.scss'],
  entryComponents: [
    MapIndicatorSummaryComponent,
    IndicatorDragDropComponent,
    MapLegendComponent,
    NoWardLevelOverlayComponent,
    LoadingScreenComponent,
    TimePeriodComponent
  ]
})
export class MainMapComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(IndicatorDragDropComponent, { static: false })
  indicatorBarComponent: IndicatorDragDropComponent;

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  subscriptions: Subscription[] = [];
  showCancelButton: boolean = false;
  cancelTimer: any;
  mouseLngLat: any;
  showNoWardDataOverlay: boolean;
  displayLoadingScreen:boolean;

  private mapBoxProperties: IMapBoxProperties;

  mapBoxMap: mapboxgl.Map;
  renderedAreas: any = null;
  sourceAreas: any = null;
  firstSymbolLayerId: string = null;
  hoveredStateId: string = null;
  hoveredTextStateId: string = null;
  startZoomLevel: number;
  allowScrollZoom: boolean;
  previousBoundaries: any;
  currentBoundaries: any;
  allowDragColor: boolean;

  fetchingPrimaryIndicatorDataCompleted:boolean = false;
  fetchingSecondaryIndicatorDataCompleted:boolean = false;

  private locationNames: IGeoLocationData[] = [];

  private readonly geoJsonProperties: Array<string> = [
    'ISO_A3',
    'SU_A3',
    'GU_A3',
    'sr_brk_a3',
    'sr_adm0_a3',
    'CONTINENT',
    'SUBREGION',
    'name',
    'F4',
    'F2',
    'CC_4',
    'MUNICNAME',
    'DISTRICT',
    'CAT_B',
    'WardID'
  ];

  private selectedPrimaryIndicatorCode: string;
  private selectedSecondaryIndicatorCode: string;

  private readonly featureState: any = {
    color: 'color',
    subregion: 'subregion',
    continentLayer: 'continentLayer'
  };

  informationDomains: Array<IInformationDomain> = Array<IInformationDomain>();
  timePeriods: Array<ICovidTimePeriod> = Array<ICovidTimePeriod>();
  selectedTimePeriod: string;
  selectedLocation: LocationModel;
  selectedIndicators: IIndicator[] = new Array<IIndicator>();
  primaryIndicatorDataMap = new Map();
  secondIndicatorDataMap = new Map();
  covidQuestions: ICovidQuestion[];

  maxRangeNoPrimaryIndicator: number = 100;
  maxRangeNoSecondaryIndicator: number = 100;
  currentMarkers: Array<mapboxgl.Marker> = [];
  defaultPrimaryIndicator: IndicatorModel = new IndicatorModel();
  isClickIntoEvent: boolean;

  private mapGeoLevel: MapGeoLevel = MapGeoLevel.SouthAfrica;

  southAfricaParentGeoLocationId = 0;
  private readonly southAfricaCenterLngLat = [28.216463, -29.695307];
  parentGeoLocationId: number = this.southAfricaParentGeoLocationId;
  geoLevelBreadcrumbs: IGeoLevelBreadCrumb[] = new Array<IGeoLevelBreadCrumb>();

  defaultGeoLevelBreadCrumb: IGeoLevelBreadCrumb = {
    geoLocationId: this.southAfricaParentGeoLocationId,
    geoLocationName: 'South Africa',
    lngLat: this.southAfricaCenterLngLat
  };

  showPopup: boolean;

  indicatorValues: ICovidIndicatorData[] = new Array<ICovidIndicatorData>();

  private indicatorRefreshTimer: any;
  private readonly dataExpirationTime: number;

  constructor(
    private covidDataService: CovidDataService,
    private mapboxService: MapBoxService,
    private route: ActivatedRoute,
    private spinner?: NgxSpinnerService
  ) {
    this.mapBoxProperties = this.mapboxService.getMapBoxProperties();
    (mapboxgl as any).accessToken = this.mapBoxProperties.accessToken;
    this.dataExpirationTime = this.covidDataService.dataExpirationTime;

    this.route.queryParams.subscribe(params => {
      this.covidDataService.instanceId = params['instance-id'];
      this.covidDataService.token = params['token'];

      if (typeof this.covidDataService.instanceId !== 'undefined') {
        this.getTimePeriods();
        this.GetGeoLocationNames();
        this.setDefaultParentGeoLocation();
      }
    });
  }

  private getIndicatorValuesForGeoLevel(): ICovidIndicatorData[] {
    return this.indicatorValues.filter(
      a => a.geoLevel === GetApiGeoLevel(this.mapGeoLevel)
    );
  }

  private setDefaultParentGeoLocation(): void {
    this.showLoadingScreen();
    this.covidDataService.getParentGeoLocationId().subscribe(
      (res: IParentGeoLocation[]) => {
        if (!res) this.hideLoadingScreen();
        else {
          this.southAfricaParentGeoLocationId = res[0].id;
          this.parentGeoLocationId = this.southAfricaParentGeoLocationId;

          this.defaultGeoLevelBreadCrumb = {
            geoLocationId: this.southAfricaParentGeoLocationId,
            geoLocationName: 'South Africa',
            lngLat: this.southAfricaCenterLngLat
          };

          this.setDefaultBreadCrumb();
          this.getCovidQuestions();
          this.getInformationDomains();
          this.buildMap();
        }
      },
      error => this.hideLoadingScreen()
    );
  }

  private setDefaultBreadCrumb(): void {
    this.geoLevelBreadcrumbs.push(this.defaultGeoLevelBreadCrumb);
  }

  ngOnInit(): void {
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
      this.removeMapBoxLogo();
      this.removeStreetMapLogo();
    });

    this.subscriptions.push(this.resizeSubscription$);
  }

  ngAfterViewInit(): void {
    this.removeMapBoxLogo();
    this.removeStreetMapLogo();
    this.startIndicatorDataExpirationTimer();
  }

  ngOnDestroy(): void {
    this.cancelAllSubscriptions();
  }

  private removeMapBoxLogo(): void {
    this.removeElements('mapboxgl-ctrl-logo');
  }

  private removeStreetMapLogo(): void {
    this.removeElements('mapboxgl-ctrl-bottom-right');
  }

  private removeElements(className: string): void {
    const elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  private buildMap(): void {
    this.mapBoxMap = new mapboxgl.Map({
      container: 'map',
      style: `${this.mapBoxProperties.styles.prefix}${this.mapBoxProperties.styles.heatMap}`,
      accessToken: this.mapBoxProperties.accessToken,
      minZoom: 4.5,
      zoom: 1,
      center: this.southAfricaCenterLngLat
    });

    this.addMapControls();
    this.disableMapFunctionality();
    this.setupOnMapLoad();
  }

  private setupOnMapLoad(): void {
    this.mapBoxMap.on('load', () => {
      this.mapBoxMap.doubleClickZoom.disable();
      this.removeMapBoxLogo();
      this.removeStreetMapLogo();
      this.setFirstSymbolLayerId();
      this.addMapEvents();
    });
  }

  private addDragStartEvent(): void {
    this.mapBoxMap.on(
      'dragstart',
      function() {
        this.previousBoundaries = this.mapBoxMap.getBounds();
      }.bind(this)
    );
  }

  private addPaintOnDragEndEvent(): void {
    this.mapBoxMap.on(
      'dragend',
      function() {
        this.currentBoundaries = this.mapBoxMap.getBounds();
        if (!this.allowDragColor) return;
        this.repaintMap(this.mapGeoLevel);
      }.bind(this)
    );
  }

  private getCovidQuestions(): void {
    this.subscriptions.push(
      this.covidDataService.getQuestions().subscribe(res => {
        this.covidQuestions = [...res];
      })
    );
  }

  private GetGeoLocationNames(): void {
    const allowedGeoLevels = [
      MapGeoLevel.SouthAfrica,
      MapGeoLevel.District,
      MapGeoLevel.Local
    ];

    allowedGeoLevels.forEach(geoLevel => {
      this.covidDataService.getGeoLocations(geoLevel).subscribe(
        (res: IGeoLocationData[]) => {
          this.locationNames = [...this.locationNames, ...res];
        },
        error => console.error(error)
      );
    });
  }

  private getInformationDomains(): void {
    this.showLoadingScreen();
    this.subscriptions.push(
      this.covidDataService.getIndicators().subscribe(
        (res: ICovidIndicator[]) => {
          this.informationDomains = CovidIndicatorToInformationDomainMap(res);
          this.setDefaultIndicators();
        },
        error => this.hideLoadingScreen()
      )
    );
  }

  private getTimePeriods(): void {
    this.showLoadingScreen();
    this.subscriptions.push(
      this.covidDataService.getTimePeriods().subscribe(
        (res: ICovidTimePeriod[]) => {
          this.timePeriods = res;
          this.setDefaultTimePeriod();
        },
        error => this.hideLoadingScreen()
      )
    );
  }

  private setDefaultTimePeriod(): void {
    if (this.timePeriods.length == 0) {
      this.hideLoadingScreen();
      return;
    }
    this.selectedTimePeriod = this.timePeriods[0].period;
  }

  private setDefaultIndicators(): void {
    if (this.informationDomains.length == 0) {
      this.hideLoadingScreen();
      return;
    }

    if (this.informationDomains[0].indicators.length === 0) {
      this.hideLoadingScreen();
      return;
    }

    this.setPrimaryDefaultIndicator();
    this.setSecondaryDefaultIndicator();
  }

  private setPrimaryDefaultIndicator(): void {
    if (!defaultSelectedIndicators.primaryIndicatorCode) {
      this.setFirstAvailablePrimaryIndicator();
      return;
    }

    const primaryIndicator = this.getIndicatorByCode(
      defaultSelectedIndicators.primaryIndicatorCode
    );

    if (!primaryIndicator) this.setFirstAvailablePrimaryIndicator();
    else {
      this.selectIndicator(primaryIndicator);
    }
  }

  private setSecondaryDefaultIndicator(): void {
    if (!defaultSelectedIndicators.primaryIndicatorCode) {
      return;
    }

    if (!defaultSelectedIndicators.secondaryIndicatorCode) {
      return;
    }

    const secondaryIndicator = this.getIndicatorByCode(
      defaultSelectedIndicators.secondaryIndicatorCode
    );

    if (secondaryIndicator) this.selectIndicator(secondaryIndicator);
  }

  private getIndicatorByCode(indicatorCode: string): IIndicator | any {
    let indicators: IIndicator[] = [];
    this.informationDomains.forEach(
      a => (indicators = [...indicators, ...a.indicators])
    );

    return indicators.find(a => a.indicatorCode == indicatorCode);
  }

  private setFirstAvailablePrimaryIndicator(): void {
    const indicator = this.informationDomains[0].indicators[0];
    this.selectIndicator(indicator);
  }

  cancelAllSubscriptions(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());

    this.subscriptions = [];
  }

  private addMapEvents(): void {
    this.onMapHoverShowSummary();
    this.addOnceOffMapIdleEvent();
    this.addFlyToOnZoomEndEvent();
    this.setMouseLngLat();

    this.setMouseMoveTextEvent();
    this.setMouseLeaveTextEvent();
    this.addDrillDownOnSingleClickEvent();
    this.addDrillDownDoubleClickEvent();
    this.addCursorChangeOnMouseEvent();
    this.handleZoomEvent();
    this.onMouseWheelAllowZoomEvent();
    this.setMouseMoveEvent();
    this.setMouseLeaveEvent();
    this.addPaintOnDragEndEvent();
    this.addDragStartEvent();
  }

  private addMapControls(): void {
    this.mapBoxMap.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false
      })
    );
  }

  private disableMapFunctionality(): void {
    this.mapBoxMap.dragRotate.disable();
  }

  private getGeoJsonSources(): string {
    switch (this.mapGeoLevel) {
      case MapGeoLevel.SouthAfrica: {
        return this.mapBoxProperties.shapeFilesGeoJson.provincial;
      }
      case MapGeoLevel.District: {
        return this.mapBoxProperties.shapeFilesGeoJson.districtMunicipality;
      }
      case MapGeoLevel.Local: {
        return this.mapBoxProperties.shapeFilesGeoJson.localMunicipality;
      }
      case MapGeoLevel.Ward: {
        return this.mapBoxProperties.shapeFilesGeoJson.ward;
      }
      default: {
        console.log('getGeoJsonSources', 'Error getting GeoJson Source');
        return this.mapBoxProperties.shapeFilesGeoJson.localMunicipality;
      }
    }
  }

  private addGeoJsonSources(): void {
    this.mapBoxMap.addSource(primaryIndicatorDefinition.sourceId, {
      type: 'vector',
      url: this.getGeoJsonSources()
    });
  }

  private addTextGeoJsonSource(): void {
    this.mapBoxMap.addSource('textsource', {
      type: 'geojson',
      data: this.getCustomGeoJson()
    });
  }

  getIndicatorValuesForGeoLevelForLocation(
    indicatorValues?: ICovidIndicatorData[]
  ): ICovidIndicatorData[] {
    if (!indicatorValues) indicatorValues = this.indicatorValues;

    return indicatorValues.filter(
      a =>
        a.geoLevel === GetApiGeoLevel(this.mapGeoLevel) &&
        +a.parentGeoLocationID === this.parentGeoLocationId
    );
  }

  private filterLocationNamesPerGeoLevel(): IGeoLocationData[] {
    if (this.geoLevelBreadcrumbs.length > 1) {
      let locationNames = this.locationNames.filter(
        a =>
          a.levelName === GetApiGeoLevel(this.mapGeoLevel) &&
          a.parentLocationID == this.parentGeoLocationId
      );

      if (locationNames.length === 0) {
        locationNames = this.locationNames.filter(
          a =>
            a.levelName === GetApiGeoLevel(this.mapGeoLevel) &&
            a.provinceLocationId == this.parentGeoLocationId
        );
      }

      if (locationNames.length === 0) {
        locationNames = this.locationNames.filter(
          a =>
            a.levelName === GetApiGeoLevel(this.mapGeoLevel) &&
            a.levelDescription == `${this.parentGeoLocationId}`
        );
      }

      return locationNames;
    }

    return this.locationNames.filter(
      a => a.levelName === GetApiGeoLevel(this.mapGeoLevel)
    );
  }

  private getCustomGeoJson(): any {
    let geoJson = {
      type: 'FeatureCollection',
      features: []
    };

    this.filterLocationNamesPerGeoLevel().forEach(a => {
      let feature = {
        type: 'Feature',
        id: a.locationID,
        properties: {
          title: FormatGeoLocationName(a.locationName, this.mapGeoLevel)
        },
        geometry: {
          type: 'Point',
          coordinates: [a.longitude, a.latitude]
        }
      };

      geoJson.features.push(feature);
    });

    return geoJson;
  }

  private getLayerSource(): string {
    switch (this.mapGeoLevel) {
      case MapGeoLevel.SouthAfrica: {
        return this.mapBoxProperties.shapeFiles.provincial;
      }
      case MapGeoLevel.District: {
        return this.mapBoxProperties.shapeFiles.districtMunicipality;
      }
      case MapGeoLevel.Local: {
        return this.mapBoxProperties.shapeFiles.localMunicipality;
      }
      case MapGeoLevel.Ward: {
        return this.mapBoxProperties.shapeFiles.ward;
      }
      default: {
        console.log('getLayerSource', 'Error getting Layer Source');
        return this.mapBoxProperties.shapeFiles.provincial;
      }
    }
  }

  private addPrimaryIndicatorLayer(featureState: string): void {
    this.mapBoxMap.addLayer(
      {
        id: primaryIndicatorDefinition.layerId,
        source: primaryIndicatorDefinition.sourceId,
        'source-layer': this.getLayerSource(),
        type: 'fill',
        paint: {
          'fill-color': [
            'case',
            ['==', ['feature-state', featureState], null],
            '#CED0D7',
            ['feature-state', featureState]
          ],
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            1,
            0.5
          ],
          'fill-outline-color': [
            'case',
            ['==', ['feature-state', 'border'], null],
            primaryIndicatorDefinition.borderColor,
            ['feature-state', 'border']
          ]
        }
      }
    );
  }

  private addPrimarySourceAndLayer() {
    this.addGeoJsonSources();
    this.addPrimaryIndicatorLayer(this.featureState.color);
  }

  private filterIndicatorValuesByIndicatorCode(
    indicatorCode: string
  ): Array<ICovidIndicatorData> {
    return this.getIndicatorValuesForGeoLevel().filter(
      a =>
        a.indicatorCode === indicatorCode &&
        +a.parentGeoLocationID === this.parentGeoLocationId
    );
  }

  private filterIndicatorValuesByIndicatorCodeAndGeoLevel(
    indicatorCode: string
  ): Array<ICovidIndicatorData> {
    if(this.breadcrumbOnlyContainsRoot()){
     return this.indicatorValues.filter(
        a =>
          a.indicatorCode === indicatorCode &&
          a.geoLevel === GetApiGeoLevel(this.mapGeoLevel)
      );
    }

    if(this.mapGeoLevel === this.geoLevelBreadcrumbs.length -1 ){
      return this.filterIndicatorValuesByIndicatorCode(indicatorCode);
    }

    return this.getIndicatorValuesForZoom(indicatorCode);
  }

  private getIndicatorValuesForZoom(indicatorCode:string): ICovidIndicatorData[] {
    let indicatorValues = this.indicatorValues.filter(a => a.indicatorCode === indicatorCode && +a.parentGeoLocationID === this.parentGeoLocationId);

    if(indicatorValues.length === 0) return [];

    let lastParentGeoLevel = indicatorValues[0].geoLevel;

    while(lastParentGeoLevel !== GetApiGeoLevel(this.mapGeoLevel)){
      indicatorValues = [...this.getChildIndicatorValues(indicatorValues, indicatorCode)];

      if(indicatorValues.length === 0) return [];

      lastParentGeoLevel = indicatorValues[0].geoLevel;
    }
      
    return indicatorValues.filter(a => a.geoLevel === GetApiGeoLevel(this.mapGeoLevel));
  }

  private getChildIndicatorValues(parentIndicatorValues:ICovidIndicatorData[], indicatorCode:string): ICovidIndicatorData[] {
    let values:ICovidIndicatorData[] = [];

    if(!parentIndicatorValues) return [];

    parentIndicatorValues.forEach(parent => {
      values = [...values, ...this.indicatorValues.filter(child => child.indicatorCode === indicatorCode && parent.geoLocationID === +child.parentGeoLocationID)];
    })

    return values;
  }

  private breadcrumbOnlyContainsRoot(): boolean {
    return this.geoLevelBreadcrumbs.length === 1;
  }

  private getIndicatorValues(indicatorCode: string, completedFetchingData:string): Promise<any> {
    return new Promise((resolve, reject) => {
      const complete = function() {
        this[completedFetchingData] = true;
        resolve();
      };

      this[completedFetchingData] = false;

      if (!this.hasIndicatorValueDataInList(indicatorCode)) {
        this.showLoadingScreen();
        this.subscriptions.push(
          this.covidDataService
            .getC19Data(indicatorCode,this.selectedTimePeriod)
            .subscribe(
              res => {
                if (!res) {
                  this.hideLoadingScreen();
                } else {
                  this.indicatorValues = [
                    ...this.convertToPercentageOrReturnValue(res),
                    ...this.indicatorValues
                  ];

                  this.updateFetchedDateTime(indicatorCode);
                }
                this[completedFetchingData] = true;
                resolve();
              },
              error => {
                this.hideLoadingScreen();
                this[completedFetchingData] = true;
              }
            )
        );
      } else {
        // Important! To allow enough time for map to load source
        setTimeout(complete.bind(this), 1000);
      }
    });
  }

  private updateFetchedDateTime(indicatorCode: string): void {
    if (
      typeof this.selectedIndicators.filter(
        a => a.indicatorCode === indicatorCode
      )[0] === 'undefined'
    )
      return;

    this.selectedIndicators.filter(
      a => a.indicatorCode === indicatorCode
    )[0].fetchedDateTime = new Date();
  }

  private convertToPercentageOrReturnValue(
    indicatorValues: ICovidIndicatorData[]
  ): ICovidIndicatorData[] {
    indicatorValues.forEach(a => {
      const selectedIndicator = this.selectedIndicators.find(
        b => b.indicatorCode === a.indicatorCode
      );
      if (IsPercentage(selectedIndicator.unitOfMeasure)) {
        a.value = ToPercentage(a.value);
      }
    });

    return indicatorValues;
  }

  hasIndicatorValueDataForGeoLevel(indicatorCode: string): boolean {
    return this.indicatorValues.some(
      a =>
        a.geoLevel === GetApiGeoLevel(this.mapGeoLevel) &&
        a.indicatorCode === indicatorCode &&
        a.timePeriod === this.selectedTimePeriod
    );
  }

  private hasIndicatorValueDataInList(indicatorCode: string): boolean {
    return this.indicatorValues.some(
      a =>
        +a.parentGeoLocationID === this.parentGeoLocationId &&
        a.indicatorCode === indicatorCode
    );
  }

  layerExists(layerId: string): boolean {
    const layer = this.mapBoxMap.getLayer(layerId);
    return layer === null || typeof layer === 'undefined';
  }

  private setSelectedIndicators(): void {
    Object.assign(
      this.selectedIndicators,
      this.informationDomains
        .reduce(extractIndicatorsFromDomains, [])
        .reduce(extractSelectedIndicators, this.selectedIndicators)
    );
    this.triggerChangeOnIndicatorBar();
  }

  private buildPrimaryIndicatordDataObject(
    indicatorValues: ICovidIndicatorData[]
  ): void {
    this.primaryIndicatorDataMap = new Map<any, any>();
    this.maxRangeNoPrimaryIndicator = 0;
    indicatorValues.forEach(indicatorValue => {
      this.maxRangeNoPrimaryIndicator = this.getPrimaryIndicatorMaxRangeNo(
        indicatorValue
      );

      let geoLocation = indicatorValue.geoLocation;

      this.primaryIndicatorDataMap[
        this.getGeoLocationOrCode(indicatorValue)
      ] = {
        val: indicatorValue.value,
        region: indicatorValue.geoLevel,
        subregion: geoLocation,
        geoLocationId: indicatorValue.geoLocationID,
        continent: this.getContinent()
      };
    });
  }

  getGeoLocationOrCode(indicatorValue: ICovidIndicatorData): string {
    return this.mapGeoLevel == MapGeoLevel.SouthAfrica
      ? `${indicatorValue.geoLocation}`
      : indicatorValue.geoLocationCode;
  }

  private getContinent(): string {
    if (this.geoLevelBreadcrumbs.length >= 2)
      return this.geoLevelBreadcrumbs[1].geoLocationName;

    return '';
  }

  private getPrimaryIndicatorMaxRangeNo(
    indicatorValue: ICovidIndicatorData
  ): number {
    return this.maxRangeNoPrimaryIndicator === 0 ||
      +indicatorValue.value > this.maxRangeNoPrimaryIndicator
      ? +indicatorValue.value
      : this.maxRangeNoPrimaryIndicator;
  }

  private addOnceOffMapIdleEvent(): void {
    this.mapBoxMap.once('idle', this.setPrimaryIndicatorColors.bind(this));
  }

  private setPrimaryIndicatorColors(): void {
    /* first check if can get source since isSourceLoaded throws console error if source doesnt exist*/
    if (!this.mapBoxMap.getSource(primaryIndicatorDefinition.sourceId)) return;

    if (!this.mapBoxMap.isSourceLoaded(primaryIndicatorDefinition.sourceId)) {
      this.goToLayerLevel(this.getZoomDefault());
    }

    this.setPrimaryIndicatorHeatMap();
  }

  private setFirstSymbolLayerId(): void {
    const layers = this.mapBoxMap.getStyle().layers;

    let firstSymbolId;
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].type === 'symbol') {
        firstSymbolId = layers[i].id;
        break;
      }
    }

    this.firstSymbolLayerId = firstSymbolId;
  }

  useGeoLocationNameOrCode(locationData: IGeoLocationData): string {
    return this.mapGeoLevel === MapGeoLevel.SouthAfrica
      ? locationData.locationName
      : locationData.locationCode;
  }

  private setPrimaryIndicatorHeatMap(): void {
    this.setSourceAreas();
    for (const key of this.locationNames) {
      const locationKey = this.useGeoLocationNameOrCode(key);
      const areas = this.getMapIdFromData(locationKey);
      if (typeof areas === 'undefined') {
        continue;
      }

      areas.forEach(area => {
        const featureStateValue = this.getPrimaryFeaturestateValue(locationKey);
        this.mapBoxMap.setFeatureState(
          {
            source: primaryIndicatorDefinition.sourceId,
            sourceLayer: this.getLayerSource(),
            id: area.id
          },
          {
            color: featureStateValue,
            subregion: featureStateValue,
            continentLayer: featureStateValue,
            area: featureStateValue,
            border: this.getBorderColor(locationKey),
            noPrimaryIndicatorData: this.hasPrimaryIndicatorDataForSummary(
              locationKey
            )
          }
        );
      });
    }

    setTimeout(this.hideLoadingScreen.bind(this), 1000);
  }

  private getMapIdFromData(key: string): Array<any> {
    this.setSourceAreas();
    const sourceAreaLocation = this.sourceAreas.filter(
      sourceArea =>
        sourceArea.id && this.hasGeoJsonProperty(sourceArea.properties, key)
    );

    if (typeof sourceAreaLocation !== 'undefined') {
      return sourceAreaLocation;
    }
  }

  private getWardNumberOrLocationName(value: string): string {
    if (this.mapGeoLevel !== MapGeoLevel.Ward) {
      return value;
    }

    const regExp = /\(([^)]+)\)/;
    const matches = regExp.exec(value);
    if (!matches) return '';

    return matches[1];
  }

  addCursorChangeOnMouseEvent(): void {
    this.mapBoxMap.on(
      'mousemove',
      primaryIndicatorDefinition.layerId,
      function(e) {
        if (e.features.length === 0) return;

        if (this.areaHasSecondaryIndicator(e.features[0].properties)) {
          this.setCursorToPointer();
          return;
        }

        if (!e.features[0].state?.area) {
          this.setCursorToGrab();
        } else {
          !this.isInArea(this.getKey(e.features[0].properties))
            ? this.setCursorToGrab()
            : this.setCursorToPointer();
        }
      }.bind(this)
    );
  }

  private setCursorToGrab(): void {
    this.mapBoxMap.getCanvas().style.cursor = 'grab';
  }

  private setCursorToPointer(): void {
    this.mapBoxMap.getCanvas().style.cursor = 'pointer';
  }

  private areaHasSecondaryIndicator(mapElement: any): boolean {
    const key = this.getKey(mapElement);
    return typeof this.secondIndicatorDataMap[key] !== 'undefined';
  }

  private hasGeoJsonProperty(renderedArea: any, key: string): boolean {
    return this.geoJsonProperties.some(
      propKey => renderedArea[propKey] === key
    );
  }

  private setMouseMoveEvent(): void {
    this.mapBoxMap.on(
      'mousemove',
      primaryIndicatorDefinition.layerId,
      function(e) {
        if (e.features.length > 0) {
          if (this.hoveredStateId) {
            this.mapBoxMap.setFeatureState(
              {
                source: primaryIndicatorDefinition.sourceId,
                id: this.hoveredStateId,
                sourceLayer: this.getLayerSource()
              },
              { hover: false }
            );
          }
          this.hoveredStateId = e.features[0].id;
          this.mapBoxMap.setFeatureState(
            {
              source: primaryIndicatorDefinition.sourceId,
              id: this.hoveredStateId,
              sourceLayer: this.getLayerSource()
            },
            { hover: true }
          );
        }
      }.bind(this)
    );
  }

  private setMouseLeaveEvent(): void {
    this.mapBoxMap.on(
      'mouseleave',
      primaryIndicatorDefinition.layerId,
      function() {
        if (this.hoveredStateId) {
          this.mapBoxMap.setFeatureState(
            {
              source: primaryIndicatorDefinition.sourceId,
              id: this.hoveredStateId,
              sourceLayer: this.getLayerSource()
            },
            { hover: false }
          );
        }
        this.setCursorToGrab();
        this.hoveredStateId = null;
      }.bind(this)
    );
  }

  private addTextLayer(): void {
    this.mapBoxMap.addLayer({
      id: textLayerDefinition.layerId,
      source: 'textsource',
      type: 'symbol',
      layout: {
        'icon-image': '',
        'text-field': '{title}',
        'text-font': [
          'literal',
          ['Open Sans Semibold', 'Arial Unicode MS Bold']
        ],
        'icon-size': 0.25,
        'text-justify': 'center',
        'text-offset': [0, 0.252]
      },
      paint: {
        'text-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#2534F7',
          '#171B2C'
        ]
      }
    });
  }

  private setMouseMoveTextEvent(): void {
    this.mapBoxMap.on(
      'mousemove',
      textLayerDefinition.layerId,
      function(e) {
        if (e.features.length > 0 && this.mapGeoLevel !== MapGeoLevel.Ward) {
          if (this.hoveredTextStateId) {
            this.mapBoxMap.setFeatureState(
              {
                source: 'textsource',
                id: this.hoveredTextStateId
              },
              { hover: false }
            );
          }
          this.hoveredTextStateId = e.features[0].id;
          if (this.hoveredTextStateId) {
            this.mapBoxMap.setFeatureState(
              {
                source: 'textsource',
                id: this.hoveredTextStateId
              },
              { hover: true }
            );

            this.mapBoxMap.getCanvas().style.cursor = 'pointer';
          }
        }
      }.bind(this)
    );
  }

  private setMouseLeaveTextEvent(): void {
    this.mapBoxMap.on(
      'mouseleave',
      textLayerDefinition.layerId,
      function() {
        if (this.hoveredTextStateId) {
          this.mapBoxMap.setFeatureState(
            {
              source: 'textsource',
              id: this.hoveredTextStateId
            },
            { hover: false }
          );
          this.mapBoxMap.getCanvas().style.cursor = 'grab';
        }
        this.hoveredTextStateId = null;
      }.bind(this)
    );
  }

  private hideSummary(): void {
    this.selectedLocation = new LocationModel();
    this.showPopup = false;
  }

  onMapHoverShowSummary(): void {
    this.mapBoxMap.on(
      'mousemove',
      primaryIndicatorDefinition.layerId,
      function(mapElement) {
        if (
          this.isMapStateUndefinedAndNoSecondaryIndicator(mapElement) ||
          this.hasNoPrimaryAndSecondaryIndicator(mapElement)
        ) {
          if (!this.isInArea(this.getKey(mapElement.features[0].properties))) {
          this.hideSummary();
          return;
          }
        }
        if (!this.isInArea(this.getKey(mapElement.features[0].properties))) {
          this.hideSummary();
          return;
        }

        this.showPopup = true;

        const indicatorValues: ICovidIndicatorData[] = [
          ...this.getIndicatorValuesForGeoLevel().filter(a =>
            this.existsInMapProperties(this.getGeoLocationOrCode(a), mapElement)
          )
        ];

        this.buildSelectedLocation(
          this.appendMissingIndicators(indicatorValues, mapElement)
        );
      }.bind(this)
    );

    this.mapBoxMap.on(
      'mouseleave',
      primaryIndicatorDefinition.layerId,
      function() {
        this.showPopup = false;
      }.bind(this)
    );
  }

  appendMissingIndicators(
    indicatorValues: ICovidIndicatorData[],
    mapElement: any
  ) {
  
    const geoLocationId = mapElement.features[0].id;
   
    let geoLocationName: string = this.getLocationNameBasedOnGeoLevel(
      mapElement
    );

    this.selectedIndicators.forEach(indicator=> {
      if(indicatorValues.findIndex(a => a.indicatorCode === indicator.indicatorCode) === -1){
        const missingIndicator: ICovidIndicatorData = {
          geoLocation: geoLocationName,
          geoLocationID: geoLocationId,
          indicatorCode: indicator.indicatorCode,
          indicatorName: indicator.indicatorName,
          value: this.shouldNotDisplayNoData(indicator.indicatorCode) ? 0 : -1,
          geoLevel: '',
          geoLocationLevelCode: '',
          informationDomain: '',
          geoLocationCode: '',
          indicatorValueID: 0,
          latitude: 0,
          longitude: 0,
          parentGeoLocationCode: '',
          parentGeoLocationID: '',
          parentGeoLocationLevelCode: '',
          parentGeoLocationName: '',
          year: '',
          timePeriod: ''
        };

        indicatorValues.push(missingIndicator);
      }
    })

    return indicatorValues;
  }

  private getLocationNameBasedOnGeoLevel(mapElement: any): string {
    return this.mapGeoLevel === MapGeoLevel.SouthAfrica
      ? this.getLocationName('locationName', mapElement)
      : this.getLocationName('locationCode', mapElement);
  }

  private getLocationName(propertyName: string, mapElement: any): string {
    const location = this.locationNames.find(
      a => a[propertyName] === this.getKey(mapElement.features[0].properties)
    );

    if (!location) return 'No location name found';

    return location.locationName;
  }

  shouldAddScreeningIndicator(indicatorValues: ICovidIndicatorData[]): boolean {
    if (
      typeof indicatorValues === 'undefined' &&
      this.isScreeningIndicatorSelected()
    )
      return true;

    if (
      this.isScreeningIndicatorSelected() &&
      indicatorValues.findIndex(
        a => a.indicatorCode === indicatorsThatShouldNotDisplayNoData[0]
      ) === -1
    ) {
      return true;
    }

    return false;
  }

  isScreeningIndicatorSelected(): boolean {
    return (
      this.selectedIndicators.findIndex(
        a => a.indicatorCode === indicatorsThatShouldNotDisplayNoData[0]
      ) > -1
    );
  }

  private isMapStateUndefinedAndNoSecondaryIndicator(mapElement): boolean {
    return (
      typeof mapElement.features[0].state === 'undefined' &&
      !this.hasSecondaryIndicator(mapElement)
    );
  }

  private hasNoPrimaryAndSecondaryIndicator(mapElement): boolean {
    return (
      !mapElement.features[0].state.noPrimaryIndicatorData &&
      !this.hasSecondaryIndicator(mapElement)
    );
  }

  private hasSecondaryIndicator(mapElement: any): boolean {
    let value = false;

    for (const key in this.secondIndicatorDataMap) {
      if (!value) {
        value = this.existsInMapProperties(key, mapElement);
      }
    }
    return value;
  }

  private existsInMapProperties(value: string, mapElement: any): boolean {
    return (
      mapElement.features[0].properties.NAME ||
      value === mapElement.features[0].properties.SUBREGION ||
      value === mapElement.features[0].properties.name ||
      value === mapElement.features[0].properties.F4 ||
      value === mapElement.features[0].properties.F2 ||
      value === mapElement.features[0].properties.CC_4 ||
      value === mapElement.features[0].properties.WardID ||
      value === mapElement.features[0].properties.MUNICNAME ||
      value === mapElement.features[0].properties.DISTRICT ||
      value === mapElement.features[0].properties.CAT_B
    );
  }

  private buildSelectedLocation(indicatorValues: ICovidIndicatorData[]): void {
    if (indicatorValues.length === 0) return;

    this.selectedLocation = new LocationModel();
    this.selectedLocation.name = FormatGeoLocationName(
      indicatorValues[0].geoLocation,
      this.mapGeoLevel
    );

    let locationIndicatorModelList = new Array<LocationIndicatorModel>();
    indicatorValues.forEach(indicatorValue => {
      const indicator = this.selectedIndicators.find(
        a => a.indicatorCode === indicatorValue.indicatorCode
      );

      if (
        !(
          locationIndicatorModelList.findIndex(
            a => a.id === indicatorValue.indicatorCode
          ) > -1
        )
      ) {
        locationIndicatorModelList = [
          ...locationIndicatorModelList,
          mapIndicatorToLocationIndicatorModel(
            indicatorValue,
            indicator.unitOfMeasure
          )
        ];
      }
    });

    this.selectedLocation.indicators = locationIndicatorModelList;
  }

  private shouldNotDisplayNoData(indicatorCode: string): boolean {
    return indicatorsThatShouldNotDisplayNoData.includes(indicatorCode);
  }

  removeSecondIndicator(): void {
    if (this.currentMarkers === null) return;

    this.currentMarkers.forEach(marker => marker.remove());
  }

  removeIndicator(indicatorData: any): void {
    const index = this.selectedIndicators.indexOf(indicatorData.indicator, 0);
    if (index === -1) return;

    if (indicatorData.position === 2 || this.selectedIndicators.length === 2) {
      this.removeSecondIndicator();
    }

    this.selectedIndicators.splice(index, 1);

    if (this.selectedIndicators.length === 1)
      this.selectedSecondaryIndicatorCode = null;

    this.enableDisableIndicator(indicatorData.indicator.indicatorCode, false);
    this.triggerChangeOnIndicatorBar();
    this.removeIndicatorValues(indicatorData.indicator.indicatorCode);
  }

  private removeIndicatorValues(indicatorCode: string): void {
    this.indicatorValues = [
      ...this.indicatorValues.filter(a => a.indicatorCode !== indicatorCode)
    ];
  }

  private canViewWardData(): boolean {
    return this.geoLevelBreadcrumbs.length > 1;
  }

  private enableDisableIndicator(
    indicatorId: string,
    enableIndicator: boolean
  ) {
    this.informationDomains.forEach(domain => {
      const indicator = domain.indicators.find(
        i => i.indicatorCode === indicatorId
      );
      if (typeof indicator !== 'undefined') {
        indicator.isSelected = enableIndicator;
        return;
      }
    });
  }

  setSecondaryIndicator(): void {
    this.getIndicatorValues(this.selectedSecondaryIndicatorCode,'fetchingSecondaryIndicatorDataCompleted')
      .then(() => this.removeSecondIndicator())
      .then(() => this.removeTextLayerAndSource())
      .then(() => this.addTextGeoJsonSource())
      .then(() => this.addTextLayer())
      .then(() =>
        this.buildSecondIndicatorDataObject(
          this.filterIndicatorValuesByIndicatorCode(
            this.selectedSecondaryIndicatorCode
          )
        )
      ).then(() => setTimeout(this.hideLoadingScreen.bind(this), 2001));

    this.enableDisableIndicator(this.selectedSecondaryIndicatorCode, true);
    this.setSelectedIndicators();
  }

  private buildSecondIndicatorDataObject(
    indicatorValues: ICovidIndicatorData[]
  ): void {
    this.maxRangeNoSecondaryIndicator = 0;
    this.secondIndicatorDataMap = new Map<any, any>();
    indicatorValues.forEach(indicatorValue => {
      this.maxRangeNoSecondaryIndicator = this.getSecondIndicatorMaxRangeNo(
        indicatorValue
      );

      this.secondIndicatorDataMap[this.getGeoLocationOrCode(indicatorValue)] = {
        val: indicatorValue.value,
        geoLocationId: indicatorValue.geoLocationID
      };
    });

    this.displaySecondIndicator(indicatorValues);
  }

  private getSecondIndicatorMaxRangeNo(
    indicatorValue: ICovidIndicatorData
  ): number {
    return this.maxRangeNoSecondaryIndicator === 0
      ? +indicatorValue.value
      : +indicatorValue.value > this.maxRangeNoSecondaryIndicator
      ? +indicatorValue.value
      : this.maxRangeNoSecondaryIndicator;
  }

  private displaySecondIndicator(indicatorValues: ICovidIndicatorData[]): void {
    indicatorValues.forEach(indicatorValue => {
      if (indicatorValue.value > 0) {
        this.addSecondIndicatorToMap(
          +indicatorValue.longitude,
          +indicatorValue.latitude,
          this.secondIndicatorDataMap[this.getGeoLocationOrCode(indicatorValue)]
            .val
        );
      }
    });
  }

  private addSecondIndicatorToMap(lng: number, lat: number, val: string): void {
    const lngLat: mapboxgl.LngLatLike = [lng, lat];

    const circleSize =
      this.maxRangeNoSecondaryIndicator > 100
        ? secondaryIndicatorDefinition.colorScale(
            this.maxRangeNoSecondaryIndicator
          )(val)
        : secondaryIndicatorDefinition.colorScale(
            this.maxRangeNoSecondaryIndicator
          )(val, '100');

    const circelElement = this.createCircleElement(circleSize);
    const circleMarker = this.createCircleMarker(circelElement, lngLat);

    this.currentMarkers = [...this.currentMarkers, circleMarker];
  }

  private createCircleElement(circleSize: number): HTMLDivElement {
    const circleRadius: number = 50; //dont change, this makes it a circle

    const el = document.createElement('div');
    el.className = 'marker';
    el.style.background = '#FF9A02';
    el.style.width = circleSize + 'px';
    el.style.height = circleSize + 'px';
    el.style.borderRadius = circleRadius + '%';
    el.style.opacity = '0.4';
    el.style.borderStyle = 'solid';
    el.style.borderWidth = '0.808036px';
    el.style.borderColor = '#FFFFFF';
    el.style.pointerEvents = 'none';

    return el;
  }

  private createCircleMarker(
    circelElement: HTMLDivElement,
    lngLat: mapboxgl.LngLatLike
  ): mapboxgl.Marker {
    return new mapboxgl.Marker({ element: circelElement, draggable: false })
      .setLngLat(lngLat)
      .addTo(<mapboxgl.Map>this.mapBoxMap);
  }

  private setSourceAreas(): void {
    this.sourceAreas = this.mapBoxMap.querySourceFeatures(
      primaryIndicatorDefinition.sourceId,
      {
        sourceLayer: this.getLayerSource()
      }
    );
  }

  private triggerChangeOnIndicatorBar(): void {
    this.indicatorBarComponent.addItemToSortableList();
  }

  selectIndicator(indicator: IIndicator): void {
    this.enableDisableIndicator(indicator.indicatorCode, true);
    this.setSelectedIndicators();
    this.goToLastSelectedGeoLevel();
  }

  selectTimePeriod(timePeriodSelected: ICovidTimePeriod): void {
    this.selectedTimePeriod = timePeriodSelected.period;
    this.indicatorValues = [];
    this.test();
  }

  private goToLastSelectedGeoLevel(): void {
    if (this.getLastSelectedGeoLevel() < this.mapGeoLevel)
      this.mapGeoLevel = this.getLastSelectedGeoLevel();
  }

  private addContinentLayer(): void {
    this.removePrimaryIndicatorLayerAndSource();
    this.addGeoJsonSources();
    this.addPrimaryIndicatorLayer('area');
  }

  private removePrimaryIndicatorLayerAndSource(): void {
    this.removeLayerAndSource(
      primaryIndicatorDefinition.sourceId,
      primaryIndicatorDefinition.layerId
    );
  }

  private removeLayerAndSource(sourceId: string, layerId: string): void {
    let source = this.mapBoxMap.getSource(sourceId);
    if (typeof source !== 'undefined') {
      if (typeof this.mapBoxMap.getLayer(layerId) !== 'undefined')
        this.mapBoxMap.removeLayer(layerId);

      this.mapBoxMap.removeSource(sourceId);
    }
  }

  private removeTextLayerAndSource(): void {
    this.removeLayerAndSource('textsource', 'text-layer');
  }

  private bindDataToContinentLayer(): void {
    // Continent should see regions within a country e.g ECOWAS
    for (const key in this.primaryIndicatorDataMap) {
      if (typeof key === 'undefined') continue;

      const mapId = this.getMapIdFromDataSource(key);

      if (typeof mapId === 'undefined') continue;

      this.mapBoxMap.setFeatureState(
        {
          source: primaryIndicatorDefinition.sourceId,
          sourceLayer: this.getLayerSource(),
          id: mapId[0].id
        },
        {
          area: this.getPrimaryFeaturestateValue(key)
        }
      );
    }
  }

  private bindDataToSubRegionLayer(): void {
    for (const key in this.primaryIndicatorDataMap) {
      if (typeof key === 'undefined') continue;

      const areas = this.getMapIdFromData(key);
      if (typeof areas === 'undefined') continue;

      areas.forEach(area => {
        const value = this.getPrimaryFeaturestateValue(key);
        if (value) {
          this.mapBoxMap.setFeatureState(
            {
              source: primaryIndicatorDefinition.sourceId,
              sourceLayer: this.getLayerSource(),
              id: area.id
            },
            {
              area: value
            }
          );
        }
      });
    }
  }

  private bindDataToRegionLayer(): void {
    for (const key of this.locationNames) {
      const locationKey = this.useGeoLocationNameOrCode(key);
      const areas = this.getMapIdFromData(locationKey);
      if (typeof areas === 'undefined') continue;

      areas.forEach(area => {
        this.mapBoxMap.setFeatureState(
          {
            source: primaryIndicatorDefinition.sourceId,
            sourceLayer: this.getLayerSource(),
            id: area.id
          },
          {
            area: this.getPrimaryFeaturestateValue(locationKey),
            border: this.getBorderColor(locationKey),
            noPrimaryIndicatorData: this.hasPrimaryIndicatorDataForSummary(
              locationKey
            )
          }
        );
      });
    }
  }

  hasPrimaryIndicatorDataForSummary(key: string): boolean {
    return (
      typeof this.primaryIndicatorDataMap[key] !== 'undefined' ||
      this.selectedIndicators.findIndex(
        a => a.indicatorCode === indicatorsThatShouldNotDisplayNoData[0]
      ) > -1
    );
  }

  private getBorderColor(key: string): any {
    const data = this.primaryIndicatorDataMap[key];

    if (!data) return this.isInArea(key) ? '#2534F7' : '#CED0D7';

    if (data['val'] > 0) return primaryIndicatorDefinition.borderColor;

    return '#2534F7';
  }

  private getPrimaryFeaturestateValue(key: string): any {
    const data = this.primaryIndicatorDataMap[key];

    if (!data) return this.isInArea(key) ? '#EDEEF1' : '#CED0D7';

    if (data['val'] === 0) return '#FFFFFF';

    return primaryIndicatorDefinition.colorScale(
      (data['val'] / this.maxRangeNoPrimaryIndicator) * 100
    );
  }

  private isInArea(key: string): boolean {
    const locations = this.filterLocationNamesPerGeoLevel();
    if (this.geoLevelBreadcrumbs.length === 1) return true;

    if (this.mapGeoLevel === MapGeoLevel.SouthAfrica) {
      return locations.findIndex(a => a.locationName === key) !== -1;
    } else {
      return locations.findIndex(a => a.locationCode === key) !== -1;
    }
  }

  private getMapIdFromDataSource(key: string): Map<any, any> {
    this.setSourceAreas();

    for (const sourceArea of this.sourceAreas) {
      if (sourceArea.id === null || typeof sourceArea === 'undefined') continue;
      if (!this.hasGeoJsonProperty(sourceArea.properties, key)) continue;

      const dataSet = new Map();

      dataSet[0] = {
        id: sourceArea.id,
        region: sourceArea.properties.CONTINENT,
        subregion: sourceArea.properties.SUBREGION
      };

      return dataSet;
    }
  }

  private removeContinentLayer(): void {
    this.mapBoxMap.removeLayer(MapLayers.Continents);
  }

  private addRegionLayer(): void {
    this.removePrimaryIndicatorLayerAndSource();

    this.addGeoJsonSources();
    this.addPrimaryIndicatorLayer(this.featureState.subregion);
  }

  private removeRegionLayer(): void {
    this.mapBoxMap.removeLayer(MapLayers.Subregions);
  }

  private addDrillDownDoubleClickEvent(): void {
    this.mapBoxMap.on(
      'dblclick',
      primaryIndicatorDefinition.layerId,
      function(mapElement: any) {
        if (this.mapGeoLevel === MapGeoLevel.Ward) return;

        this.allowScrollZoom = false;
        this.isClickIntoEvent = true;
        this.goDownOneGeoLevel(mapElement);
      }.bind(this)
    );
  }

  private addDrillDownOnSingleClickEvent(): void {
    this.mapBoxMap.on(
      'click',
      textLayerDefinition.layerId,
      function(mapElement: any) {
        if (this.mapGeoLevel === MapGeoLevel.Ward) return;
        this.allowScrollZoom = false;
        this.isClickIntoEvent = true;

        this.goDownOneGeoLevel(mapElement, true);
      }.bind(this)
    );
  }

  updateGeoLevelBreadCrumb(
    mapProperties: any,
    geoLocationId: number,
    isHyperLink: boolean = false
  ): void {
    if (this.mapGeoLevel !== this.getLastSelectedGeoLevel()) {
      this.allowDragColor = false;
      this.buildMissingBreadcrumbItems(geoLocationId);
      return;
    }

    const geoLocationData = this.locationNames.find(
      a => a.locationID == geoLocationId
    );
    switch (this.mapGeoLevel) {
      case MapGeoLevel.SouthAfrica: {
        this.allowDragColor = false;
        this.AddToGeoLevelBreadCrumb({
          geoLocationId: geoLocationId,
          geoLocationName: isHyperLink
            ? mapProperties.title
            : mapProperties.name,
          lngLat: this.mouseLngLat
        });

        break;
      }

      case MapGeoLevel.District: {
        this.allowDragColor = false;
        this.AddToGeoLevelBreadCrumb({
          geoLocationId: geoLocationId,
          geoLocationName: isHyperLink ? mapProperties.title : mapProperties.F2,
          lngLat: this.mouseLngLat
        });
        break;
      }

      case MapGeoLevel.Local: {
        this.allowDragColor = false;
        this.AddToGeoLevelBreadCrumb({
          geoLocationId: geoLocationId,
          geoLocationName: isHyperLink
            ? mapProperties.title
            : mapProperties.MUNICNAME,
          lngLat: this.mouseLngLat
        });
        break;
      }

      case MapGeoLevel.Ward: {
        this.allowDragColor = false;
        this.AddToGeoLevelBreadCrumb({
          geoLocationId: geoLocationId,
          geoLocationName: isHyperLink
            ? mapProperties.title
            : mapProperties.WardNo,
          lngLat: this.mouseLngLat
        });
        break;
      }

      default:
        console.log("Can't add to breadcrumb");
        break;
    }
  }

  displayClearButton(): boolean {
    return (
      this.geoLevelBreadcrumbs.length > 1 &&
      this.mapGeoLevel !== MapGeoLevel.Ward
    );
  }

  clearLastBreadCrumbButStayOnSelection(): void {
    if (this.geoLevelBreadcrumbs.length === 1) return;

    this.allowDragColor = true;
    this.geoLevelBreadcrumbs = [this.defaultGeoLevelBreadCrumb];

    this.setIndicatorsOnScrollZoom();
  }

  private getKey(mapProperties: any): string {
    switch (this.mapGeoLevel) {
      case MapGeoLevel.SouthAfrica: {
        return mapProperties.name;
      }

      case MapGeoLevel.District: {
        return mapProperties.DISTRICT;
      }

      case MapGeoLevel.Local: {
        return mapProperties.CAT_B;
      }

      case MapGeoLevel.Ward: {
        return mapProperties.WardID;
      }

      default:
        console.log("Can't add to breadcrumb");
        break;
    }
  }

  nextMapGeoLevel(): void {
    switch (this.mapGeoLevel) {
      case MapGeoLevel.SouthAfrica:
        this.mapGeoLevel = MapGeoLevel.District;
        break;

      case MapGeoLevel.District:
        this.mapGeoLevel = MapGeoLevel.Local;
        break;

      case MapGeoLevel.Local:
        this.mapGeoLevel = MapGeoLevel.Ward;
        break;
    }
  }

  previousMapGeoLevel(): void {
    switch (this.mapGeoLevel) {
      case MapGeoLevel.Ward:
        this.mapGeoLevel = MapGeoLevel.Local;
        break;

      case MapGeoLevel.Local:
        this.mapGeoLevel = MapGeoLevel.District;
        break;

      case MapGeoLevel.District:
        this.mapGeoLevel = MapGeoLevel.SouthAfrica;
        break;
    }
  }

  private getGeoLocationId(mapProperties: any): number {
    let geoLocation = this.primaryIndicatorDataMap[this.getKey(mapProperties)];

    if (!geoLocation)
      geoLocation = this.secondIndicatorDataMap[this.getKey(mapProperties)];

    if (!geoLocation) return;

    return geoLocation.geoLocationId;
  }

  private goDownOneGeoLevel(
    mapElement: any,
    isHyperLink: boolean = false
  ): void {
    let geoLocationId: number;
    const mapProperties = mapElement.features[0].properties;

    if (isHyperLink) {
      geoLocationId = mapElement.features[0].id;
    } else {
      geoLocationId = this.getGeoLocationId(mapProperties);
    }

    if (!geoLocationId) return;

    this.updateGeoLevelBreadCrumb(mapProperties, geoLocationId, isHyperLink);
    switch (this.mapGeoLevel) {
      case MapGeoLevel.SouthAfrica: {
        this.parentGeoLocationId = geoLocationId;

        this.showLoadingScreen();
        this.GetWardLocationNames(geoLocationId);
        this.nextMapGeoLevel();
        this.goToLayerLevel(ShapeFileMinZoomLevel.districtMunicipality);
        this.setSecondaryIndicator();
        this.flyToLocation();
        break;
      }

      case MapGeoLevel.District: {
        this.showLoadingScreen();
        this.parentGeoLocationId = geoLocationId;
        this.nextMapGeoLevel();
        this.goToLayerLevel(ShapeFileMinZoomLevel.localMunicipality);
        this.setSecondaryIndicator();
        this.flyToLocation();
        break;
      }

      case MapGeoLevel.Local: {
        this.showLoadingScreen();
        this.parentGeoLocationId = geoLocationId;
        this.nextMapGeoLevel();
        this.goToLayerLevel(ShapeFileMinZoomLevel.ward);
        this.setSecondaryIndicator();
        this.flyToLocation();
        break;
      }

      default:
        console.log('Error on drill down');
        break;
    }
  }

  private GetWardLocationNames(provinceLocationId: number): void {
    if (
      this.locationNames.findIndex(
        a => a.levelDescription === `${provinceLocationId}`
      ) > -1
    )
      return;

    this.covidDataService
      .getGeoLocationsForWardsByProvince(provinceLocationId)
      .subscribe(
        (res: IGeoLocationMunicipalityData[]) => {
          this.locationNames = [
            ...this.locationNames,
            ...MapMunicipalityToGeoLocationData(
              this.linkLocalMunipalitiesToProvinceAndDistrict(
                res,
                provinceLocationId
              ),
              provinceLocationId
            )
          ];
        },
        error => console.error(error)
      );
  }

  private linkLocalMunipalitiesToProvinceAndDistrict(
    data: IGeoLocationMunicipalityData[],
    provinceLocationId: number
  ): IGeoLocationMunicipalityData[] {
    if(!data) return [];

    data.forEach(a => {
      const locationName = this.locationNames.find(
        b => b.locationID === a.localMunicipalityLocationID
      );

      if (locationName) {
        locationName.provinceLocationId = provinceLocationId;
        a.wardDistrictLocationId = locationName.parentLocationID;
      }
    });

    return data;
  }

  private goToLayerLevel(zoomLevel: number): void {
    this.getIndicatorValues(this.selectedPrimaryIndicatorCode,'fetchingPrimaryIndicatorDataCompleted')
      .then(() => this.removePrimaryIndicatorLayerAndSource())
      .then(() => this.removeTextLayerAndSource())
      .then(() => this.addGeoJsonSources())
      .then(() => this.addPrimaryIndicatorLayer('area'))
      .then(() => this.addTextGeoJsonSource())
      .then(() => this.addTextLayer())
      .then(() => this.mapBoxMap.setZoom(zoomLevel))
      .then(() =>
        this.buildPrimaryIndicatordDataObject(
          this.filterIndicatorValuesByIndicatorCode(
            this.selectedPrimaryIndicatorCode
          )
        )
      )
      .then(() => this.bindDataToRegionLayer())
      .then(() =>
        this.enableDisableIndicator(this.selectedPrimaryIndicatorCode, true)
      )
      .then(() => this.setSelectedIndicators())
      .then(() => setTimeout(this.setPrimaryIndicatorColors.bind(this), 1001));
  }

  private getZoomDefault(): number {
    switch (this.mapGeoLevel) {
      case MapGeoLevel.SouthAfrica:
        return ShapeFileMinZoomLevel.provincial;
      case MapGeoLevel.District:
        return ShapeFileMinZoomLevel.districtMunicipality;
      case MapGeoLevel.Local:
        return ShapeFileMinZoomLevel.localMunicipality;
      case MapGeoLevel.Ward:
        return ShapeFileMinZoomLevel.ward;
    }
  }

  private AddToGeoLevelBreadCrumb(
    geoLocationBreadcrumb: IGeoLevelBreadCrumb
  ): void {
    if (this.geoLevelBreadcrumbs.length >= 4) return;

    this.geoLevelBreadcrumbs.push(geoLocationBreadcrumb);
    this.indicatorBarComponent.addItemToSortableList();
  }

  goBackToGeoLevel(geoLevelBreadcrumb: IGeoLevelBreadCrumb): void {
    if (this.geoLevelBreadcrumbs.length <= 1) return;

    if (
      typeof geoLevelBreadcrumb === 'undefined' &&
      this.geoLevelBreadcrumbs.length > 1
    ) {
      this.geoLevelBreadcrumbs.pop();

      this.parentGeoLocationId = this.geoLevelBreadcrumbs[
        this.geoLevelBreadcrumbs.length - 1
      ].geoLocationId;
    }

    switch (this.geoLevelBreadcrumbs.indexOf(geoLevelBreadcrumb)) {
      // World view see all continents
      case MapGeoLevel.SouthAfrica:
        this.showLoadingScreen();
        this.geoLevelBreadcrumbs = [this.defaultGeoLevelBreadCrumb];
        this.mapGeoLevel = MapGeoLevel.SouthAfrica;
        this.parentGeoLocationId = this.southAfricaParentGeoLocationId;
        this.goToLayerLevel(ShapeFileMinZoomLevel.provincial);
        this.setSecondaryIndicator();
        this.setMapCenter(this.defaultGeoLevelBreadCrumb.lngLat);
        break;

      // Continent  see all regions (ECOWAS etc.)
      case MapGeoLevel.District:
        if (this.geoLevelBreadcrumbs.length >= 3)
          this.geoLevelBreadcrumbs.splice(
            2,
            this.geoLevelBreadcrumbs.length - 2
          );

        this.showLoadingScreen();
        this.mapGeoLevel = MapGeoLevel.District;
        this.parentGeoLocationId = geoLevelBreadcrumb.geoLocationId;
        this.goToLayerLevel(ShapeFileMinZoomLevel.districtMunicipality);
        this.setSecondaryIndicator();
        this.setMapCenter(geoLevelBreadcrumb.lngLat);
        break;
      case MapGeoLevel.Local:
        if (this.geoLevelBreadcrumbs.length >= 3)
          this.geoLevelBreadcrumbs.splice(3, 1);

        this.showLoadingScreen();
        this.mapGeoLevel = MapGeoLevel.Local;
        this.parentGeoLocationId = geoLevelBreadcrumb.geoLocationId;
        this.goToLayerLevel(ShapeFileMinZoomLevel.localMunicipality);
        this.setSecondaryIndicator();
        this.setMapCenter(geoLevelBreadcrumb.lngLat);
        break;
    }

    return;
  }

  private setMapCenter(lngLat?: mapboxgl.LngLatLike): void {
    if (!lngLat) lngLat = this.mouseLngLat;
    this.mapBoxMap.setCenter(lngLat);
  }

  setSelectedPrimaryIndicator(indicator: IIndicator) {
    this.goToLastSelectedGeoLevel();
    this.selectedPrimaryIndicatorCode = indicator.indicatorCode;
    switch (this.mapGeoLevel) {
      case MapGeoLevel.SouthAfrica: {
        this.goToLayerLevel(4.5);
        break;
      }

      case MapGeoLevel.District: {
        this.goToLayerLevel(6);
        break;
      }

      case MapGeoLevel.Local: {
        this.goToLayerLevel(6);
        break;
      }
      case MapGeoLevel.Ward: {
        this.goToLayerLevel(7);
        break;
      }
    }
  }

  buildMissingBreadcrumbItems(geoLocationId: number): void {
    const childLocation = this.locationNames.find(
      a => a.locationID === geoLocationId
    );
    const parentLocation = this.locationNames.find(
      a => a.locationID === childLocation.parentLocationID
    );
    const grandparentLocation = this.locationNames.find(
      a => a.locationID === parentLocation.parentLocationID
    );
    this.addMissingItemToBreadCrumb(grandparentLocation);
    this.addMissingItemToBreadCrumb(parentLocation);
    this.addMissingItemToBreadCrumb(childLocation);
    this.parentGeoLocationId = childLocation.locationID;
  }

  addMissingItemToBreadCrumb(geoLocationData: IGeoLocationData) {
    if (typeof geoLocationData === 'undefined') return;

    if (!this.existsInBreadCrumbByGeoLocationId(geoLocationData.locationID)) {
      
      if(geoLocationData.levelName === GetApiGeoLevel(MapGeoLevel.SouthAfrica)){
        this.GetWardLocationNames(geoLocationData.locationID);
      }

      this.AddToGeoLevelBreadCrumb({
        geoLocationId: geoLocationData.locationID,
        geoLocationName: geoLocationData.locationName,
        lngLat: [geoLocationData.longitude, geoLocationData.latitude]
      });
    }
  }

  existsInBreadCrumbByGeoLocationId(geoLocationId): boolean {
    return (
      this.geoLevelBreadcrumbs.findIndex(
        a => a.geoLocationId === geoLocationId
      ) > -1
    );
  }

  setSelectedSecondaryIndicator(indicator: IIndicator) {
    this.goToLastSelectedGeoLevel();
    this.selectedSecondaryIndicatorCode = indicator.indicatorCode;
    this.setSecondaryIndicator();
  }

  getIndicatorCode(): string[] {
    return [
      this.selectedPrimaryIndicatorCode,
      this.selectedSecondaryIndicatorCode
    ];
  }

  private setMouseLngLat(): void {
    this.mapBoxMap.on(
      'click',
      function(e) {
        this.mouseLngLat = e.lngLat;
      }.bind(this)
    );
  }

  private onMouseWheelAllowZoomEvent() {
    this.mapBoxMap.on(
      'wheel',
      function() {
        this.allowScrollZoom = true;
      }.bind(this)
    );
  }

  private getLastSelectedGeoLevel(): MapGeoLevel {
    if (this.geoLevelBreadcrumbs.length === 1) return MapGeoLevel.SouthAfrica;

    let mapGeoLevel: MapGeoLevel = this.geoLevelBreadcrumbs.length - 1;

    return mapGeoLevel;
  }

  private handleZoomEvent(): void {
    this.mapBoxMap.on(
      'zoomstart',
      function() {
        this.startZoomLevel = this.mapBoxMap.getZoom();
      }.bind(this)
    );

    this.mapBoxMap.on(
      'zoomend',
      function(e) {
        if (!this.allowScrollZoom) return;
        this.allowScrollZoom = false;

        if (this.isClickIntoEvent) {
          this.isClickIntoEvent = false;
          return;
        }
        const currentZoomLevel = this.mapBoxMap.getZoom();

        const maxLevelCanZoomOutGeoLevel = this.getLastSelectedGeoLevel();

        if (currentZoomLevel <= 6) {
          if (maxLevelCanZoomOutGeoLevel <= MapGeoLevel.SouthAfrica)
            this.goGeoLevel(MapGeoLevel.SouthAfrica);
          return;
        }

        if (currentZoomLevel > 6 && currentZoomLevel <= 8) {
          if (maxLevelCanZoomOutGeoLevel <= MapGeoLevel.District)
            this.goGeoLevel(MapGeoLevel.District);
          return;
        }

        if (currentZoomLevel > 8 && currentZoomLevel <= 10) {
          if (maxLevelCanZoomOutGeoLevel <= MapGeoLevel.Local)
            this.goGeoLevel(MapGeoLevel.Local);
          return;
        }

        if (currentZoomLevel > 10) {
          if (
            maxLevelCanZoomOutGeoLevel <= MapGeoLevel.Ward &&
            this.canViewWardData()
          ) {
            this.goGeoLevel(MapGeoLevel.Ward);
            return;
          } else {
            this.showNoWardDataOverlay = true;
          }
        }
      }.bind(this)
    );
  }

  goToSouthAfrica(event: any): void {
    this.showNoWardDataOverlay = false;
    this.allowScrollZoom = true;
    this.removeTextLayerAndSource();
    this.mapBoxMap.setZoom(ShapeFileMinZoomLevel.provincial);
  }

  goGeoLevel(geoLevel: MapGeoLevel): void {
    if (geoLevel === this.mapGeoLevel) return;

    this.showLoadingScreen();
    this.mapGeoLevel = geoLevel;

    this.setIndicatorsOnScrollZoom();
  }

  repaintMap(geoLevel: MapGeoLevel): void {
    if (this.mapGeoLevel === MapGeoLevel.SouthAfrica) return;
    if (!this.isOutOfBounds()) return;

    this.mapGeoLevel = geoLevel;

    this.setIndicatorsOnScrollZoom();
  }

  private setIndicatorsOnScrollZoom(): void {
    this.allowDragColor = true;
    if (!this.selectedSecondaryIndicatorCode) {
      this.setPrimaryIndicatorOnScrollZoom();
      return;
    }

    this.setSecondaryIndicatorOnScrollZoom();
  }

  private test(): void {
    if (!this.selectedSecondaryIndicatorCode) {
      this.setPrimaryIndicatorOnScrollZoom();
      return;
    }

    this.setSecondaryIndicatorOnScrollZoom();
  }

  isOutOfBounds(): boolean {
    let tolerance = 1;
    if(this.mapGeoLevel === MapGeoLevel.Ward)
      tolerance = 0.2;

    const diffInLngNE = Math.abs(
      this.previousBoundaries._ne.lng - this.currentBoundaries._ne.lng
    );
    const diffInLngSW = Math.abs(
      this.previousBoundaries._sw.lng - this.currentBoundaries._sw.lng
    );
    const diffInLatNE = Math.abs(
      this.previousBoundaries._ne.lat - this.currentBoundaries._ne.lat
    );
    const diffInLatSW = Math.abs(
      this.previousBoundaries._sw.lat - this.currentBoundaries._sw.lat
    );

    const differences = [diffInLngNE, diffInLngSW, diffInLatNE, diffInLatSW];
    return differences.some(a => a >= tolerance);
  }

  private setPrimaryIndicatorOnScrollZoom(): void {
    this.getIndicatorValueForGeoLevel(
      this.selectedPrimaryIndicatorCode,
      'fetchingPrimaryIndicatorDataCompleted'
    )
      .then(() => this.removePrimaryIndicatorLayerAndSource())
      .then(() => this.removeTextLayerAndSource())
      .then(() => this.addGeoJsonSources())
      .then(() => this.addPrimaryIndicatorLayer('area'))
      .then(() => this.addTextGeoJsonSource())
      .then(() => this.addTextLayer())
      .then(() =>
        this.buildPrimaryIndicatordDataObject(
          this.filterIndicatorValuesByIndicatorCodeAndGeoLevel(
            this.selectedPrimaryIndicatorCode
          )
        )
      )
      .then(() => this.bindDataToRegionLayer())
      .then(() =>
        this.enableDisableIndicator(this.selectedPrimaryIndicatorCode, true)
      )
      .then(() => this.setSelectedIndicators())
      .then(() => setTimeout(this.setPrimaryIndicatorColors.bind(this), 4001));
  }

  private setSecondaryIndicatorOnScrollZoom(): void {
    this.getIndicatorValueForGeoLevel(
      this.selectedSecondaryIndicatorCode,
      'fetchingSecondaryIndicatorDataCompleted'
    )
      .then(() => this.removeSecondIndicator())
      .then(() => this.removeTextLayerAndSource())
      .then(() => this.addTextGeoJsonSource())
      .then(() => this.addTextLayer())
      .then(() =>
        this.buildSecondIndicatorDataObject(
          this.filterIndicatorValuesByIndicatorCodeAndGeoLevel(
            this.selectedSecondaryIndicatorCode
          )
        )
      )
      .then(() => this.setPrimaryIndicatorOnScrollZoom());
  }

  private getIndicatorValueForGeoLevel(
    indicatorCode: string,
    completedFetchingData: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const complete = function() {
        this[completedFetchingData] = true;
        resolve();
      };

      this[completedFetchingData] = false;

      if (!this.hasIndicatorValueDataForGeoLevel(indicatorCode)) {
        this.showLoadingScreen();
        this.subscriptions.push(
          this.covidDataService
            .getC19Data(indicatorCode,this.selectedTimePeriod)
            .subscribe(
              res => {
                if (!res) {
                  this.hideLoadingScreen();                
                } else {
                  this.indicatorValues = [
                    ...this.convertToPercentageOrReturnValue(res),
                    ...this.indicatorValues
                  ];
                  this.updateFetchedDateTime(indicatorCode);
                }
                this[completedFetchingData] = true;
                resolve();
              },
              error => {
                this.hideLoadingScreen();
                this[completedFetchingData] = true;
              }
            )
        );
      } else {
        // Important! To allow enough time for map to load source
        setTimeout(complete.bind(this), 1000);
      }
    });
  }

  private addFlyToOnZoomEndEvent(): void {
    this.mapBoxMap.on(
      'zoomend',
      function(e) {
        this.flyToLocation();
      }.bind(this)
    );
  }

  private flyToLocation(): void {
    if (this.geoLevelBreadcrumbs.length === 1) return;

    const currentGeoLevel = this.getLastSelectedGeoLevel();
    if (this.mapGeoLevel >= currentGeoLevel && !this.isClickIntoEvent) return;

    const currentZoomLevel = this.mapBoxMap.getZoom();
    switch (this.mapGeoLevel) {
      case MapGeoLevel.SouthAfrica: {
        if (currentZoomLevel < ShapeFileMinZoomLevel.provincial) {
          this.flyTo(this.mouseLngLat, ShapeFileMinZoomLevel.provincial);
        }
        break;
      }
      case MapGeoLevel.District: {
        if (currentZoomLevel < ShapeFileMinZoomLevel.districtMunicipality) {
          this.flyTo(
            this.mouseLngLat,
            ShapeFileMinZoomLevel.districtMunicipality
          );
        }
        break;
      }

      case MapGeoLevel.Local: {
        if (currentZoomLevel < ShapeFileMinZoomLevel.localMunicipality) {
          this.flyTo(this.mouseLngLat, ShapeFileMinZoomLevel.localMunicipality);
        }
        break;
      }

      case MapGeoLevel.Ward: {
        if (currentZoomLevel < ShapeFileMinZoomLevel.ward) {
          this.flyTo(this.mouseLngLat, ShapeFileMinZoomLevel.ward);
        }
        break;
      }
    }
  }

  private flyTo(centerValue: any, zoomValue: number) {
    this.mapBoxMap.flyTo({ center: centerValue, zoom: zoomValue + 0.1 });
  }

  private startIndicatorDataExpirationTimer(): void {
    this.indicatorRefreshTimer = setInterval(() => {
      this.refreshIndicatorData.bind(this)();
    }, 60000);

    this.indicatorRefreshTimer.start;
  }

  private refreshIndicatorData(): void {
    let hasRemovedStaleData: boolean = false;
    const currentDate = new Date();

    this.selectedIndicators.forEach(a => {
      if (
        getDateDifferenceMs(a.fetchedDateTime, currentDate) >=
        this.dataExpirationTime
      ) {
        this.indicatorValues = [
          ...this.indicatorValues.filter(
            b => b.indicatorCode !== a.indicatorCode
          )
        ];
        hasRemovedStaleData = true;
      }
    });

    if (!hasRemovedStaleData) return;

    this.setPrimaryAndSecondaryIndicators();
  }

  private setPrimaryAndSecondaryIndicators(): void {
    this.setSelectedPrimaryIndicator(
      this.selectedIndicators.filter(
        a => a.indicatorCode === this.selectedPrimaryIndicatorCode
      )[0]
    );

    if (this.selectedIndicators.length > 1) {
      this.setSelectedSecondaryIndicator(
        this.selectedIndicators.filter(
          a => a.indicatorCode === this.selectedSecondaryIndicatorCode
        )[0]
      );
    }
  }

  cancelLoadingScreenEvent():void{
    this.displayLoadingScreen = false;
    this.cancelAllSubscriptions();
  }

  private showLoadingScreen(): void {
    this.displayLoadingScreen = true;
  }

   private hideLoadingScreen(): void {
    this.displayLoadingScreen = false;
  }

  completedFetchingData(): boolean {
    if(!this.selectedSecondaryIndicatorCode)
      this.fetchingSecondaryIndicatorDataCompleted = true;

    return this.fetchingPrimaryIndicatorDataCompleted && this.fetchingSecondaryIndicatorDataCompleted;  
  }

 toggleMap(): void {
    document
      .getElementById('mapPlaceholder')
      .classList.toggle('hidden-map');
    document.getElementById('toggleMap').classList.toggle('toggled');    

    this.toggleMapStyle();
  }

  private toggleMapStyle(): void {
    const mapSprite:string = this.mapBoxMap.getStyle().sprite;
    
    if(mapSprite.includes(this.mapBoxProperties.styles.heatMap))
        this.setMapStyle(this.mapBoxProperties.styles.satellite);
    else{
      this.setMapStyle(this.mapBoxProperties.styles.heatMap);
    }    
  }

  private setMapStyle(style:string): void {
    const mapStyle = `${this.mapBoxProperties.styles.prefix}${style}`
    this.mapBoxMap.setStyle(mapStyle);
     this.setPrimaryAndSecondaryIndicators();
  }
}
