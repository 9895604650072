import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'vp-loading-screen',
    templateUrl: './loading-screen.component.html',
  })

  export class LoadingScreenComponent implements OnInit {
    showCancelButton: boolean;
    @Output() cancelEventEmmitter: EventEmitter<void> = new EventEmitter<void>();
    cancelTimer: any;
    
    // 1min then show cancel button
    private readonly cancelButtonTimeMs = 60000;

    constructor(private spinner?: NgxSpinnerService){}

    ngOnInit():void{
        this.spinner.show();
        this.startCancelTimer()
    }

    cancel():void{
        this.cancelEventEmmitter.emit();
    }

    private startCancelTimer(): void {
        this.cancelTimer = setTimeout(() => {
          this.displayCancelButton();
        }, this.cancelButtonTimeMs);
    
        this.cancelTimer.start;
      }

      private displayCancelButton(): void {
        this.showCancelButton = true;
      }
  }