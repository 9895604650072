import { ICovidIndicator } from '@vantage-platform/store/lib/models/i-covid-indicator';
import { IInformationDomain } from '@vantage-platform/store/lib/models/i-information-domain';
import { IIndicator } from '@vantage-platform/store/lib/models/i-indicator';

export const CovidIndicatorToInformationDomainMap = (
  covidIndicators: ICovidIndicator[]
): IInformationDomain[] => {
  let informationDomains: IInformationDomain[] = new Array<IInformationDomain>();

  covidIndicators.forEach(covidIndicator => {
    if (
      informationDomains.findIndex(
        a => a.informationDomainName === covidIndicator.subCategory
      ) === -1
    ) {
      informationDomains = [
        ...informationDomains,
        {
          informationDomainName: covidIndicator.subCategory,
          indicators: new Array<IIndicator>()
        }
      ].sort(function(a, b){
        if(a.informationDomainName < b.informationDomainName) { return -1; }
        if(a.informationDomainName > b.informationDomainName) { return 1; }
        return 0;
    });
    }

    informationDomains.forEach(informationdomain => {
      if (informationdomain.informationDomainName === covidIndicator.subCategory)
        informationdomain.indicators = [
          ...informationdomain.indicators,
          {
            indicatorCode: covidIndicator.code,
            indicatorName: covidIndicator.name,
            unitOfMeasure: covidIndicator.unitOfMeasure,
            isSelected: false,
            fetchedDateTime: new Date()          
          }
        ].sort(function(a, b){
            if(a.indicatorName < b.indicatorName) { return -1; }
            if(a.indicatorName > b.indicatorName) { return 1; }
            return 0;
        });

    });
  });

  return informationDomains;
};

