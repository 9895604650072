<!-- TEMPLATE FOR CHIP DESKTOP -->
<ng-template #itemTemplate let-item="item" let-index="index">
  <div class="sortable-item">
    <div class="chip">
      <label>{{ item.value }}</label>
      <button
        *ngIf="showRemoveIndicatorButton()"
        (click)="removeIndicator(item.value)"
        class="close"
      ></button>
    </div>
  </div>
</ng-template>

<!-- TEMPLATE FOR CHIP MOBILE -->
<ng-template #itemTemplateMobile let-item="item" let-index="index">
  <div class="sortable-item">
    <div>
      <div class="chip">
        <label>{{ item.value }}</label>
        <button
          *ngIf="showRemoveIndicatorButton()"
          (click)="removeIndicator(item.value)"
          class="close"
        ></button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Actual HTML FOR DRAG-DROP DESKTOP -->
<div class="search-container desktop-only" id="searchContainer">
  <label class="searchlabel">Search Available Indicators</label>
  <div class="search-block">
    <div class="selected-options">
      <bs-sortable
        [(ngModel)]="selectedIndicators"
        fieldName="indicatorName"
        [itemTemplate]="itemTemplate"
        itemClass="chip-container"
        (onChange)="changeIndicatorOrder()"
      ></bs-sortable>

      <input
        id="searchInput"
        class="search-input"
        type="text"
        [(ngModel)] = "searchTerm"
      />
    </div>
    <button class="expand" onclick="expandSearch()"><span></span></button>
  </div>

  <div class="megamenu-container">
    <div class="megamenu" id="megaMenu">
      <vp-indicator-search
        [(informationDomains)]="informationDomains"
        [(searchTerm)] = "searchTerm"
        [(covidQuestions)]= "covidQuestions"
        [(showDesktopVersionInd)] = "showDesktopVersionInd"
        (selectIndicatorEventEmitter)="selectIndicator($event)"
        (removeAllSelectedIndicatorsEventEmmitter)="removeAllSelectedIndicators()"
      ></vp-indicator-search>
    </div>
  </div>
</div>

<!-- Actual HTML FOR DRAG-DROP MOBILE-->
<div class="search-container mobile-only" id="searchContainer">
  <div class="search-block">
    <div class="selected-options">
      <!-- bsSortable -->

      <bs-sortable
        [(ngModel)]="selectedIndicatorsMobile"
        fieldName="indicatorName"
        itemClass="chip-container"
        [itemTemplate]="itemTemplateMobile"
        (onChange)="changeIndicatorOrder()"
      ></bs-sortable>

    </div>
    <button onclick="expandSearch()" class="expand">
      <span></span>
    </button>
  </div>
</div>

<div class="mobile-search" id="mobileSearch">
  <div class="search-header">
    <button class="back-button" onclick="expandSearch()"></button>
    <input
      class="search-input"
      onfocus="collapseTags()"
      onblur="showTags()"
      placeholder="Search Available Indicators"
      [(ngModel)] = "searchTerm"
    />
  </div>
  <div class="search-list-container">
    <div class="selected-options" id="mobileOptions">
      <!-- bsSortable -->

      <bs-sortable
        [(ngModel)]="selectedIndicatorsMobileMegaMenu"
        fieldName="indicatorName"
        itemClass="chip-container"
        [itemTemplate]="itemTemplateMobile"
        (onChange)="changeIndicatorOrder()"
      ></bs-sortable>
    </div>
    <!-- Fix show more show less for mobile - with freddie -->
    <!-- <button
      class="showbtn"
      id="toggleMobileOptionsDisplayBtn"
      onclick="toggleMobileOptionsDisplay()"
    >
      <span class="icon"></span><span>Show&nbsp;</span
      ><span class="more">More</span><span class="less">Less</span>
    </button> -->
    <div class="megamenu-container">
      <div class="megamenu" id="megaMenuMobile">
        <div class="grid-container">
          <!-- IndicatorSearch -->
          <vp-indicator-search
            [(informationDomains)]="informationDomains"
            [(searchTerm)] = "searchTerm"
            [(covidQuestions)]= "covidQuestions"
            [(showDesktopVersionInd)] = "!showDesktopVersionInd"
            (selectIndicatorEventEmitter)="selectIndicator($event)"
          ></vp-indicator-search>
        </div>
      </div>
    </div>
  </div>
</div>
