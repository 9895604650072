import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ICovidIndicator } from '../models/i-covid-indicator';
import { ICovidIndicatorData } from '../models/i-covid-indicator-data';
import { ICovidQuestion } from '../models/i-covid-question';
import { environment } from 'apps/rpm/src/environments/environment';
import { IParentGeoLocation } from '../i-parent-geo-location';
import { MapGeoLevel } from '../../../../profiles/profiles-screen/src/enums/enums';
import { GetApiGeoLevel } from '../../../../profiles/profiles-screen/src/data-utilities/functions/ApiDataMappers';
import { IGeoLocationData } from '../models/i-geo-location-data';
import { ICovidTimePeriod } from '../models/i-covid-time-period';

declare var require: any;

export class CovidSettings {
  host: string;
  apiBaseUrl: string;
  ocpApimSubscriptionKey: string;
}

@Injectable({
  providedIn: 'root'
})
export class CovidDataService {
  private readonly languageId: number = 1;
  private readonly covidSettings: CovidSettings;
  private readonly rootLevelCode: string;
  readonly dataExpirationTime:number;

  instanceId: number;
  token: string;

  constructor(private http: HttpClient) {
    this.covidSettings = environment.covidSettings;
    this.rootLevelCode = environment.rootLevelCode;
    this.dataExpirationTime = environment.covidSettings.dataExpirationTime;
  }

  getIndicators(): Observable<ICovidIndicator[]> {
    const url = `${this.covidSettings.apiBaseUrl}indicators?instance-id=${this.instanceId}`;
    const headers = this.getHeaders();

    return this.http.get(url, { headers }) as Observable<ICovidIndicator[]>;
  }

  getTimePeriods(): Observable<ICovidTimePeriod[]> {
    const url = `${this.covidSettings.apiBaseUrl}timeperiods?instance-id=${this.instanceId}`;
    const headers = this.getHeaders();

    return this.http.get(url, { headers }) as Observable<ICovidTimePeriod[]>;
  }

  getC19Data(
    indicatorCode: string,
    timePeriod: string
  ): Observable<ICovidIndicatorData[]> {
    if (typeof indicatorCode === 'undefined' || indicatorCode === null || typeof timePeriod === 'undefined' || timePeriod === null)
      return of(new Array<ICovidIndicatorData>());

    const encodedIndicatorCode = encodeURIComponent(indicatorCode);
    const encodedTimePeriod = encodeURIComponent(timePeriod);
    const url = `${this.covidSettings.apiBaseUrl}c19data?instance-id=${this.instanceId}&indicator-code=${encodedIndicatorCode}&time-period=${encodedTimePeriod}`;

    const headers = this.getHeaders();

    return this.http.get(url, { headers }) as Observable<
      ICovidIndicatorData[]
    >;
  }

  getParentGeoLocationId(): Observable<IParentGeoLocation[]> {
    const url = `${this.covidSettings.apiBaseUrl}geolocations/geolocation/parent?instance-id=${this.instanceId}&language-id=${this.languageId}&level-code=${this.rootLevelCode}`;
    const headers = this.getHeaders();

    return this.http.get(encodeURI(url), { headers }) as Observable<
      IParentGeoLocation[]
    >;
  }

  getGeoLocationsForWardsByProvince(provinceLocationId:number):any {
    const url = `${this.covidSettings.apiBaseUrl}geolocations/municipalities?instance-id=${this.instanceId}&level-name=ward&province-location-id=${provinceLocationId}`;
    const headers = this.getHeaders();

    return this.http.get(encodeURI(url), { headers }) as Observable<
      any[]
    >;
  }

  getGeoLocations(mapGeoLevel: MapGeoLevel): Observable<IGeoLocationData[]> {
    const url = `${this.covidSettings.apiBaseUrl}geolocations?instance-id=${this.instanceId}&level-name=${GetApiGeoLevel(mapGeoLevel)}`;
    const headers = this.getHeaders();

    return this.http.get(encodeURI(url), { headers }) as Observable<
      IGeoLocationData[]
    >;
  }

  getQuestions(): Observable<ICovidQuestion[]> {
    return of(
      require('libs/profiles/profiles-screen/src/mocks/questions.json')
    ) as Observable<ICovidQuestion[]>;
  }

  private getHeaders(): any {
    let headers = {
      token: `${this.token}`
    };

    return headers;
  }
}
