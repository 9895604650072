import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'apps/rpm/src/environments/environment';
import { Observable } from 'rxjs';
import { IMapBoxProperties } from '../models/i-mapbox-properties';

export class MapboxProp {
  username: string;
  baseUrl: string;
  accessToken: string;
  allowedUrls: string[];
}

@Injectable({
  providedIn: 'root'
})
export class MapBoxService {
  private mapboxProperties: IMapBoxProperties;
  private readonly addDaysToExpiry = 1;

  constructor(private httpClient: HttpClient) {
    this.mapboxProperties = environment.mapbox;
  }

  createTempAccessToken(): Observable<any> {
    //https://docs.mapbox.com/api/accounts/#create-a-temporary-token

    let date = new Date();
    const expiryDate = date.setDate(date.getDate() + this.addDaysToExpiry);

    let url: string = `${this.mapboxProperties.baseUrl}/tokens/v2/${this.mapboxProperties.username}?access_token=${this.mapboxProperties.accessToken}`;
    let body = {
      expires: `${expiryDate}`,
      note: 'Create token for Broadreach',
      scopes: [
        'styles:read',
        'fonts:read',
        'datasets:read',
        'vision:read',
        'styles:tiles'
      ],
      allowedUrls: this.mapboxProperties.allowedUrls
    };

    return this.httpClient.post(url, body) as Observable<any>;
  }

  getMapBoxProperties = (): IMapBoxProperties => environment.mapbox;
}
