import { Component, Input, Output, EventEmitter, OnInit, AfterViewChecked, OnChanges, SimpleChanges } from '@angular/core';
import { ICovidTimePeriod } from '@vantage-platform/store/lib/models/i-covid-time-period';

@Component({
  selector: 'vp-time-period',
  templateUrl: './time-period.component.html',
  styleUrls: ['./time-period.component.scss']
})
export class TimePeriodComponent implements OnChanges {
  @Input() timePeriods: ICovidTimePeriod[];

  @Output() selectTimePeriodEventEmitter: EventEmitter<ICovidTimePeriod> = new EventEmitter<ICovidTimePeriod>();

  selectedTimePeriod: string;

  ngOnChanges(changes: SimpleChanges)
  {
    if (changes['timePeriods'] && this.timePeriods.length !== 0) {
        this.selectedTimePeriod = this.timePeriods[0].period;
    }  
  }

  selectTimePeriod(timePeriod: ICovidTimePeriod)
  {
    this.selectTimePeriodEventEmitter.emit(timePeriod);
    this.selectedTimePeriod = timePeriod.period;
  }
}