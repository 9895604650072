import { Component, Input } from '@angular/core';
import { LocationModel } from '../../models/location.model';
import { LocationIndicatorModel } from '../../models/location-indicator.model';

@Component({
  selector: 'vp-map-indicator-summary',
  templateUrl: './map-indicator-summary.component.html',
  styleUrls: ['map-indicator-summary.component.scss']
})
export class MapIndicatorSummaryComponent {
  @Input() selectedLocation: LocationModel;
  @Input() indicatorCodeOrder: Array<string>;

  private readonly noDataText: string = 'No Data';
  private readonly primaryIndicatorClass: string = "indicator1";
  private readonly secondaryIndicatorClass: string = "indicator2";

  getSelectedIndicators(): Array<LocationIndicatorModel> {
    let list = new Array<LocationIndicatorModel>();
    if (typeof this.selectedLocation === 'undefined') return list;

    this.indicatorCodeOrder.forEach(indicatorCode => {
      list = [...list, ...this.getSelectedLocation(indicatorCode)];
    });

    return list;
  }

  private getSelectedLocation(indicatorCode: string): LocationIndicatorModel[] {
    return this.selectedLocation.indicators.filter(
      indicator => indicator.isSelected && indicator.id === indicatorCode
    );
  }

  canDisplaySummary(): boolean {
    return (
      typeof this.selectedLocation !== 'undefined' &&
      this.getSelectedIndicators().length > 0
    );
  }

  getIndicatorValueClass(indicatorCode: string): string {
    const index = this.indicatorCodeOrder.indexOf(indicatorCode);
    switch(index){
      case 0: return this.primaryIndicatorClass;
      case 1: return this.secondaryIndicatorClass;
      default: "";
    }
  }

  getIndicatorValue(value: string): string {
    return value === '-1' || value === "-1.00 %" ? this.noDataText : value;
  }
}
