import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'no-ward-level',
  templateUrl: './no-ward-level-overlay.component.html',
  styleUrls: ['no-ward-level-overlay.component.scss']
})
export class NoWardLevelOverlayComponent {
  @Output() goToSouthAfricaEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(){}

  gobackToSouthAfrica(): void {
    this.goToSouthAfricaEventEmitter.emit();
  }
}
