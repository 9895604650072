import * as d3 from 'd3';

export const defaultSelectedIndicators = {
    primaryIndicatorCode: "C19.PPL.SCRND",
    secondaryIndicatorCode: ""
}

export const indicatorsThatShouldNotDisplayNoData = ["C19.PPL.SCRND"];

export const textLayerDefinition = {
    layerId: "text-layer",
    sourceId: "textsource"
}

export const primaryIndicatorDefinition = {
    layerId: "primary-indicator-fills",
    sourceId: "primary-indicator-src-id",
    colorScaleRange: ['#E5E7FE', '#7C85FA'],
    borderColor:"#FFFFFF", 
    defaultColor: "#CED0D7",
    colorScale: d3
    .scaleLinear<string>()
    .domain([0, 100])
    .range(['#E5E7FE', '#7C85FA'])
}

export const secondaryIndicatorDefinition = {
    layerId: "unclustered-point",
    sourceId: "geojsonCountries",
    defaultColor:"#FF9A02",
    colorScale: (max:number, maxRange:string = "100") => d3
    .scaleLinear<string>()
    .domain([0, max])
    .range(['10', maxRange])
}