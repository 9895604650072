// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  covidSettings:{
    host: "https://covid-maps-dev.govantage.io/",
    apiBaseUrl:"https://vantage-dev.azure-api.net/vantage/covid19/v1/",
    ocpApimSubscriptionKey:"01ee4e0bcdd148a0a0d50ba822816714",
    dataExpirationTime:900000
  },
  rootLevelCode:"WROO",
  mapbox: {
    accessToken: 'pk.eyJ1IjoibWlsZXNubWwiLCJhIjoiY2s1bmhucjlrMDQ0MzNtbnJneXk5NnpyYiJ9.VBOZvK8dOSufBxQElkNjxg',
    styles:{
      prefix:'mapbox://styles/milesnml/',
      heatMap:'ck7rirjha0s3a1iqrkuonezl8',
      satellite: 'ckc4sihdr10go1ik187rj4mhy'
    },
    baseUrl: "https://api.mapbox.com",
    allowedUrls:[],
    username: "milesnml",
    shapeFilesGeoJson:{
      profiles: 'mapbox://milesnml.3olihp62',
      ward: 'mapbox://milesnml.bohg18uh',
      localMunicipality: 'mapbox://milesnml.5ktx8j3o',
      districtMunicipality: 'mapbox://milesnml.ddvjlxgh',
      provincial: 'mapbox://milesnml.848z8s0y'
    },
    shapeFiles:{
      profiles:'ne_10m_admin_0_countries-6hsvp3',
      ward:'MDBShapefiles2016-d59iui',
      localMunicipality:'2016-Boundaries-Local-cm7rc1',
      districtMunicipality:'2016-Boundaries-District-8pl9tn',
      provincial:'Igismap-bqx5zf'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
